import React from "react";
import "./App.css";
import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "./HOC/Layout/Layout";
import Home from "./containers/Home/Home";
import "@babel/polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Contact from "./containers/Contact/Contact";
import About from "./containers/About/About";
import Portfolios from "./containers/Portfolios/Portfolios";

const App = (props) => {
  let routes = (
    <Switch>
      <Route path="/" render={(props) => <Home {...props} />} exact />
      <Route path="/contact" render={(props) => <Contact {...props} exact />} />
      <Route path="/about" render={(props) => <About {...props} exact />} />
      <Route
        path="/portfolios"
        render={(props) => <Portfolios {...props} exact />}
      />
      <Redirect to="/" />
    </Switch>
  );

  return (
    <Layout showToolbar={true} showGoUp={true} showFooter={true}>
      {/* <Suspense fallback={<p>Loading...</p>}> */}
      {routes}
      {/* </Suspense> */}
    </Layout>
  );
};

export default App;
