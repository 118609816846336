import React from "react";
import styled from "styled-components";
import CodinativeIcon from "../../assets/Svgs/Layout/code-logo.svg";
import TeamWorkIcon from "../../assets/Svgs/Other/teamwork.svg";
import CallIcon from "../../assets/Svgs/Footer/call.svg";
import EmailIcon from "../../assets/Svgs/Other/email.svg";
import LocationIcon from "../../assets/Svgs/Other/location.svg";
import QRIcon from "../../assets/Svgs/Other/qr-code.svg";
import { Colors } from "../../shared/Colors";

const CodinativeCard = (props) => {
  return (
    <CodinativeCardWrapper>
      <div className="codinative-card-box">
        <div className="codinative-card-front">
          <div className="codinative-in-front">
            <img
              src={CodinativeIcon}
              alt="c"
              className="codinative-logo-front disable-highlight"
            />
            <p className="codinative-text-front disable-highlight">
              Codinative
            </p>
            <p className="codinative-detail-text-front disable-highlight">
              codinative is tomorrow's website
            </p>
          </div>
        </div>
        <div className="codinative-card-back">
          <div className="codinative-in-back">
            <img
              src={CodinativeIcon}
              alt="c"
              className="codinative-logo-back disable-highlight"
            />
            <p className="codinative-text-back disable-highlight">Codinative</p>
            <p className="codinative-detail-text-back disable-highlight">
              codinative is tomorrow's website
            </p>
          </div>
          <div className="codinative-contact-in-back">
            <div className="codinative-contact-box">
              <p className="teamWork-text-in-back disable-highlight">
                Web Developer team
              </p>
              <img
                src={TeamWorkIcon}
                alt="s-i"
                className="teamWork-icon-in-back disable-highlight"
              />
            </div>
            <div className="codinative-contact-box">
              <p className="call-text-in-back disable-highlight">
                +989028384749
              </p>
              <img
                src={CallIcon}
                alt="s-i"
                className="call-icon-in-back disable-highlight"
              />
            </div>
            <div className="codinative-contact-box">
              <p className="email-text-in-back disable-highlight">
                codinative@gmail.com
              </p>
              <img
                src={EmailIcon}
                alt="s-i"
                className="email-icon-in-back disable-highlight"
              />
            </div>
            <div className="codinative-contact-box">
              <p className="location-text-in-back disable-highlight">
                Iran, Tehran
              </p>
              <img
                src={LocationIcon}
                alt="s-i"
                className="location-icon-in-back disable-highlight"
              />
            </div>
          </div>
          <div className="qr-code-in-back">
            <img
              src={QRIcon}
              alt="q"
              className="qr-logo-back disable-highlight"
            />
          </div>
        </div>
      </div>
    </CodinativeCardWrapper>
  );
};

const CodinativeCardWrapper = styled.div`
  width: 80%;
  margin: 80px auto;

  .codinative-card-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0;
  }

  .codinative-card-front {
    width: 500px;
    height: 240px;
    background-color: rgba(120, 19, 254, 0.04);
    border-top: 20px solid ${Colors.purple};
    border-bottom: 20px solid ${Colors.purple};
    border-radius: 20px;
    margin: 20px 0;
    box-shadow: 0 0 8px #dddedf;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .codinative-card-back {
    width: 500px;
    height: 240px;
    background-color: rgba(120, 19, 254, 0.04);
    border-top: 20px solid ${Colors.purple};
    border-bottom: 20px solid ${Colors.purple};
    border-radius: 20px;
    margin: 20px 0;
    box-shadow: 0 0 8px #dddedf;
    position: relative;
  }
  .codinative-in-front {
    text-align: center;
  }
  .codinative-logo-front {
    width: 60px;
    height: 60px;
  }
  .codinative-text-front {
    font-family: "Satisfy", cursive;
    font-size: 25px;
    color: ${Colors.purple};
    margin: 0;
  }
  .codinative-detail-text-front {
    font-family: "Satisfy", cursive;
    font-size: 16px;
    color: ${Colors.purple};
    margin: 0;
  }

  .codinative-in-back {
    position: absolute;
    top: 10px;
    left: 20px;
  }
  .codinative-logo-back {
    width: 35px;
    height: 35px;
  }
  .codinative-text-back {
    font-family: "Satisfy", cursive;
    font-size: 20px;
    color: ${Colors.dark};
    margin: 0;
  }
  .codinative-detail-text-back {
    font-family: "Satisfy", cursive;
    font-size: 14px;
    color: ${Colors.dark};
    margin: 0;
  }

  .codinative-contact-in-back {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .codinative-contact-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 15px 0;
  }
  .teamWork-text-in-back {
    font-family: "Satisfy", cursive;
    font-size: 20px;
    color: ${Colors.dark};
    margin: 0;
  }
  .teamWork-icon-in-back {
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }
  .call-text-in-back {
    font-family: "Satisfy", cursive;
    font-size: 16px;
    color: ${Colors.dark};
    margin: 0;
  }
  .call-icon-in-back {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
  .email-text-in-back {
    font-family: "Satisfy", cursive;
    font-size: 16px;
    color: ${Colors.dark};
    margin: 0;
  }
  .email-icon-in-back {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
  .location-text-in-back {
    font-family: "Satisfy", cursive;
    font-size: 18px;
    color: ${Colors.dark};
    margin: 0;
  }
  .location-icon-in-back {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  .qr-code-in-back {
    position: absolute;
    bottom: 10px;
    left: 20px;
  }
  .qr-logo-back {
    width: 80px;
    height: 80px;
  }

  //RESPANSIVE
  @media screen and (max-width: 1280px) {
    .codinative-card-box {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 580px) {
    .codinative-card-front {
      width: 300px;
      height: 140px;
    }
    .codinative-card-back {
      width: 300px;
      height: 140px;
    }
    .codinative-logo-back {
      width: 25px;
      height: 25px;
    }
    .codinative-text-back {
      font-size: 16px;
    }
    .codinative-detail-text-back {
      font-size: 12px;
    }
    .codinative-contact-box {
      margin: 5px 0;
    }
    .teamWork-text-in-back {
      font-size: 16px;
    }
    .teamWork-icon-in-back {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    .call-text-in-back {
      font-size: 12px;
    }
    .call-icon-in-back {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    .email-text-in-back {
      font-size: 12px;
    }
    .email-icon-in-back {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    .location-text-in-back {
      font-size: 12px;
    }
    .location-icon-in-back {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }

    .qr-code-in-back {
      position: absolute;
      bottom: 10px;
      left: 20px;
    }
    .qr-logo-back {
      width: 40px;
      height: 40px;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default CodinativeCard;
