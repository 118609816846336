import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../../shared/Colors";

const More = (props) => {
  return (
    <MoreWrapper changeBackgroundToolbar={props.changeBackgroundToolbar}>
      <div className="more-box" onMouseLeave={props.onClose}>
        <NavLink
          onClick={props.onClose}
          className="each-li-box disable-highlight"
          to="/contact"
          exact
        >
          contact
        </NavLink>
        <NavLink
          onClick={props.onClose}
          className="each-li-box disable-highlight"
          to="/about"
          exact
        >
          about
        </NavLink>
        <NavLink
          onClick={props.onClose}
          className="each-li-box disable-highlight"
          to="/portfolios"
          exact
        >
          portfolios
        </NavLink>
      </div>
    </MoreWrapper>
  );
};

const MoreWrapper = styled.div`
  width: 150px;
  position: absolute;
  top: 20px;
  right: 50px;
  color: ${Colors.dark};
  font-family: "Comfortaa", cursive;
  font-size: 14px;
  transition: 1000ms;
  animation: myfirst 200ms linear alternate;
  @keyframes myfirst {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .each-li-box {
    display: block;
    text-decoration: none;
    color: ${Colors.dark};
    padding: 10px 8px;
    margin: 4px 0;
    transition: 200ms;

    background-color: ${(props) =>
      props.changeBackgroundToolbar
        ? Colors.light
        : "rgba(253, 254, 255, 0.7)"};
    border-radius: 5px;
    box-shadow: 0px 0px 2px ${Colors.purple};

    cursor: pointer;
    :hover {
      background-color: ${Colors.purple};
      color: ${Colors.light};
    }
  }

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(More);
