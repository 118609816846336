import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../shared/Colors";
import GoogleImage from "../../assets/Images/Blogs/google.jpg";
import ProgrammingImage from "../../assets/Images/Blogs/programming.jpg";
import SeoImage from "../../assets/Images/Blogs/seo.jpg";

const Blogs = (props) => {
  const [motion, setMotion] = useState(true);
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -1980) {
      setMotion(false);
    } else if (currPos.y > -1880) {
      setMotion(true);
    }
  });

  return (
    <BlogsWrapper id={props.id} motion={motion}>
      <p className="main-title disable-highlight">Blogs</p>
      <div className="blogs-box">
        <div className="left-blog">
          <div className="blog-box">
            <div className="left-top-box"></div>
            <div className="bottom-box">
              <p className="blog-title disable-highlight">
                What's Google doing?
              </p>
              <p className="blog-text disable-highlight">
                Google is currently working on a project that will make its
                search engines and crawlers extremely smart and can understand
                js6, which will have a significant impact on the performance of
                sites.
              </p>
            </div>
          </div>
        </div>
        <div className="middle-blog">
          <div className="blog-box">
            <div className="middle-top-box"></div>
            <div className="bottom-box">
              <p className="blog-title disable-highlight">
                Who is the best Programmer?
              </p>
              <p className="blog-text disable-highlight">
                A good programmer is a programmer who, in addition to having a
                high coding experience, can solve problems quickly and in the
                simplest way, and order and coherence can be observed in her
                code.
              </p>
            </div>
          </div>
        </div>
        <div className="right-blog">
          <div className="blog-box">
            <div className="right-top-box"></div>
            <div className="bottom-box">
              <p className="blog-title disable-highlight">
                What is the impact of SEO on your site??
              </p>
              <p className="blog-text disable-highlight">
                SEO is important in a competitive market. If your website is
                ranked higher by Google, it will increase the credibility of
                your site and can make your site more visible.
              </p>
            </div>
          </div>
        </div>
        <div className="codinative-text disable-highlight">
          <div className="each-codinative-text">Codinative</div>
          <div className="each-codinative-text">Codinative</div>
          <div className="each-codinative-text">Codinative</div>
        </div>
      </div>
    </BlogsWrapper>
  );
};

const BlogsWrapper = styled.div`
  width: 80%;
  margin: 40px auto;
  overflow: hidden;
  font-family: "Comfortaa", cursive;

  .main-title {
    font-size: 30px;
    font-weight: 600;
    color: ${Colors.darkPurple};
    transition: 1200ms ease;
    opacity: ${(props) => (props.motion ? "0" : "1")};
  }

  .blogs-box {
    padding: 10px;
    display: flex;
    position: relative;
  }
  .left-blog {
    width: 33%;
    margin: 10px;
    background-color: ${Colors.light};
    box-shadow: 0 0 10px #dddedf;
    border: 1px solid ${Colors.light};
    border-radius: 5px;
    transform: ${(props) =>
      props.motion ? "translate(-800px, 0)" : "translate(0,0)"};
    opacity: ${(props) => (props.motion ? "0" : "1")};
    overflow: hidden;
    transition: 1000ms ease;
    :hover {
      transform: translate(0, -15px);
      border: 1px solid ${Colors.purple};
      box-shadow: 0 0 2px ${Colors.purple};
    }
  }
  .middle-blog {
    width: 33%;
    margin: 10px;
    background-color: ${Colors.light};
    box-shadow: 0 0 10px #dddedf;
    border: 1px solid ${Colors.light};
    border-radius: 5px;
    transform: ${(props) =>
      props.motion ? "translate(0, 800px)" : "translate(0,0)"};
    opacity: ${(props) => (props.motion ? "0" : "1")};
    overflow: hidden;
    transition: 1000ms ease;
    :hover {
      transform: translate(0, -15px);
      border: 1px solid ${Colors.purple};
      box-shadow: 0 0 2px ${Colors.purple};
    }
  }
  .right-blog {
    width: 33%;
    margin: 10px;
    background-color: ${Colors.light};
    box-shadow: 0 0 10px #dddedf;
    border: 1px solid ${Colors.light};
    border-radius: 5px;
    transform: ${(props) =>
      props.motion ? "translate(800px, 0)" : "translate(0,0)"};
    opacity: ${(props) => (props.motion ? "0" : "1")};
    overflow: hidden;
    transition: 1000ms ease;
    :hover {
      transform: translate(0, -15px);
      border: 1px solid ${Colors.purple};
      box-shadow: 0 0 2px ${Colors.purple};
    }
  }
  .left-top-box {
    width: 100%;
    height: 200px;
    background-image: url(${GoogleImage});
    background-position: center;
    background-repeat: space;
    background-size: cover;
    border-radius: 4px 4px 0 0;
  }
  .middle-top-box {
    width: 100%;
    height: 200px;
    background-image: url(${ProgrammingImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px 4px 0 0;
  }
  .right-top-box {
    width: 100%;
    height: 200px;
    background-image: url(${SeoImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px 4px 0 0;
  }
  .bottom-box {
    padding: 10px;
  }
  .blog-title {
    font-size: 16px;
    margin: 0;
  }
  .blog-text {
    font-size: 14px;
    margin: 10px 0;
  }

  .codinative-text {
    width: 100%;
    position: absolute;
    bottom: 20px;
    left: 24px;
    z-index: -1;
    display: flex;
    align-items: center;
  }

  .each-codinative-text {
    width: 33%;
    z-index: -1;
    font-size: 10px;
    color: ${Colors.purple};
  }

  //RESPANSIVE
  @media screen and (max-width: 1020px) {
    width: 100%;

    .main-title {
      text-align: center;
    }
  }
  @media screen and (max-width: 820px) {
    .blogs-box {
      display: block;
    }
    .codinative-text {
      display: none;
    }
    .left-blog {
      width: 80%;
      margin: 10px auto;
      text-align: center;
      :hover {
        transform: translate(0, -5px);
      }
    }
    .middle-blog {
      width: 80%;
      margin: 10px auto;
      text-align: center;
      :hover {
        transform: translate(0, -5px);
      }
    }
    .right-blog {
      width: 80%;
      margin: 10px auto;
      text-align: center;
      :hover {
        transform: translate(0, -5px);
      }
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(Blogs);
