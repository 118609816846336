import ShopImage from "../../assets/Images/Portfolios/digital-shop.jpg";
import CodinativeImage from "../../assets/Images/Portfolios/codinative.jpg";
import Rating from "../../assets/Images/Portfolios/rating.jpg";
import React from "react";

const codinativeDescription = () => {
  return (
    <>
      <p className="left-text  disable-highlight">
        Our team website, created by the newest method including
        <span className="left-freamwork  disable-highlight">
          `ReactJs`
        </span> 'n{" "}
        <span className="left-freamwork  disable-highlight">`Laravel`</span>
      </p>
      <p className="left-text  disable-highlight">
        The site is designed to be fully responsive
      </p>
      <p className="left-text  disable-highlight">
        Designed and developed entirely by Codinative's experienced team
      </p>
      <p className="left-text  disable-highlight">
        This is our home so make yourself at home.
      </p>
    </>
  );
};

const shopDescription = () => {
  return (
    <>
      <p className="left-text  disable-highlight">
        It's a shop for digital device, created by the newest method including
        <span
          className="left-freamwork  disable-highlight"
          onClick={() => (window.location = `https://nextjs.com`)}
        >
          `NesxtJs`
        </span>{" "}
        'n{" "}
        <span
          className="left-freamwork  disable-highlight"
          onClick={() => (window.location = `https://laravel.com`)}
        >
          `Laravel`
        </span>
      </p>
      <p className="left-text  disable-highlight">
        The site is designed to be fully responsive
      </p>
      <p className="left-text  disable-highlight">
        Designed and developed entirely by Codinative's experienced team
      </p>
      <p className="left-text  disable-highlight">
        The site is just a sample we have designed to gain your trust
      </p>
    </>
  );
};

const ratingDescription = () => {
  return (
    <>
      <p className="left-text  disable-highlight">
        The rating system, created by the newest method including
        <span className="left-freamwork  disable-highlight">
          `ReactJs`
        </span> 'n{" "}
        <span className="left-freamwork  disable-highlight">`Laravel`</span>
      </p>
      <p className="left-text  disable-highlight">
        The site is designed to be fully responsive
      </p>
      <p className="left-text  disable-highlight">
        Designed and developed entirely by Codinative's experienced team
      </p>
      <p className="left-text  disable-highlight">
        The site is a place everybody can make a survey and other people can
        participate in it.
      </p>
    </>
  );
};

export const portfoliosArray = [
  {
    name: "Codinative",
    address: "codinative.com",
    image: CodinativeImage,
    description: codinativeDescription(),
    under_design: false,
  },
  {
    name: "Digital online shop",
    address: "shop.codinative.ir",
    image: ShopImage,
    description: shopDescription(),
    under_design: false,
  },
  {
    name: "Rating system",
    address: "rating.codinative.ir",
    image: Rating,
    description: ratingDescription(),
    under_design: true,
  },
];
