/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../shared/Colors";
import CodeIcon from "../../assets/Svgs/Layout/code-logo.svg";
import MoreIcon from "../../assets/Svgs/Layout/more.svg";
import CallIcon from "../../assets/Svgs/Footer/call.svg";
import { NavLink } from "react-router-dom";
import More from "./More/More";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const Toolbar = (props) => {
  const [showMoreBox, setShowMoreBox] = useState(false);
  const [changeBackgroundToolbar, setChangeBackgroundToolbar] = useState(false);
  useScrollPosition(({ prevPos, currPos }) => {
    if (window.location.pathname !== "/") {
      setChangeBackgroundToolbar(true);
    } else {
      if (currPos.y < -500) {
        setChangeBackgroundToolbar(true);
      } else if (currPos.y > -500) {
        setChangeBackgroundToolbar(false);
      }
    }
  });

  return (
    <ToolbarWrapper
      changeBackgroundToolbar={changeBackgroundToolbar}
      dontBeChrome={props.dontBeChrome}
    >
      <div className="toolbar-box">
        <ul className="ul-tag">
          <span className="responsive-span-1">
            <li
              className="li-tag without-cursor"
              onClick={() => (window.location.href = "tel:+989028384749")}
            >
              <a className="small-scale disable-highlight">
                <img
                  src={CallIcon}
                  alt="call"
                  className="call-icon disable-highlight"
                />{" "}
                free consultation : +989028384749
              </a>
            </li>

            <NavLink className="navlink-class" to="/contact" exact>
              <li className="li-tag disable-highlight">contact</li>
            </NavLink>
            <NavLink className="navlink-class" to="/about" exact>
              <li className="li-tag disable-highlight">about</li>
            </NavLink>
            {/* <NavLink className="navlink-class" to="/web-design" exact>
              <li className="li-tag disable-highlight">Web Design</li>
            </NavLink> */}
            <NavLink className="navlink-class" to="/portfolios" exact>
              <li className="li-tag disable-highlight">portfolios</li>
            </NavLink>
          </span>
          <span className="responsive-span-2">
            <li className="li-tag" onClick={() => setShowMoreBox(!showMoreBox)}>
              <img
                src={MoreIcon}
                alt="lo"
                className="main-logo disable-highlight"
              />
            </li>
            <li className="li-tag without-cursor">
              <span className="small-scale disable-highlight">
                <img
                  src={CallIcon}
                  alt="call"
                  className="call-icon disable-highlight"
                  onClick={() => (window.location.href = "tel:+989028384749")}
                />{" "}
                +989028384749
              </span>
            </li>
          </span>

          <li
            className="li-tag codinative-name"
            onClick={() => (window.location.href = "/")}
          >
            <img
              src={CodeIcon}
              alt="lo"
              className="main-logo disable-highlight"
            />
            <span className="disable-highlight">Codinative</span>
          </li>
        </ul>
      </div>
      {showMoreBox && (
        <More
          onClose={() => setShowMoreBox(false)}
          changeBackgroundToolbar={changeBackgroundToolbar}
        />
      )}
    </ToolbarWrapper>
  );
};

const ToolbarWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
  box-shadow: ${(props) =>
    props.changeBackgroundToolbar ? `0 0 10px #dddedf` : "none"};
  background-color: ${(props) =>
    props.changeBackgroundToolbar ? Colors.light : "rgba(253, 254, 255, 0.7)"};

  .toolbar-box {
    width: 80%;
    margin: auto;
  }
  .ul-tag {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: ${Colors.dark};
  }
  .li-tag {
    font-family: "Comfortaa", cursive;
    font-size: 15px;
    position: relative;
    text-align: center;
    padding: 14px 12px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    float: right;
    cursor: ${(props) => (props.dontBeChrome ? "unset" : "pointer")};
    transition: 200ms;
  }
  .navlink-class {
    text-decoration: none;
    color: ${Colors.dark};
    :hover {
      .li-tag:before {
        content: "";
        position: absolute;
        left: 10%;
        bottom: 0px;
        height: 0px;
        width: 20%;
        border-bottom: 3px solid ${Colors.purple};
        border-radius: 5px;
      }
    }
  }
  .main-logo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .codinative-name {
    float: left;
  }
  // .without-cursor {
  //   cursor: unset;
  // }
  .small-scale {
    transform: scale(0.8, 0.8);
    display: flex;
    align-items: center;
  }
  .call-icon {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
  .active {
    color: ${Colors.purple};
    .li-tag:before {
      content: "";
      position: absolute;
      left: 10%;
      bottom: 0px;
      height: 0px;
      width: 20%;
      border-bottom: 3px solid ${Colors.purple};
      border-radius: 5px;
    }
  }
  .responsive-span-2 {
    display: none;
  }

  //RESPANSIVE
  @media screen and (max-width: 1000px) {
    .li-tag {
      font-size: 14px;
      padding: 14px 8px;
    }
    .main-logo {
      width: 18px;
      height: 18px;
    }
  }
  @media screen and (max-width: 860px) {
    .li-tag {
      font-size: 12px;
      padding: 14px 8px;
    }
    .main-logo {
      width: 16px;
      height: 16px;
    }
  }
  @media screen and (max-width: 800px) {
    .toolbar-box {
      width: unset;
      margin: 0 10px;
    }
  }
  @media screen and (max-width: 590px) {
    .responsive-span-1 {
      display: none;
    }
    .responsive-span-2 {
      display: block;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(Toolbar);
