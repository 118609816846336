import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import React, { useState } from "react";
import styled from "styled-components";
import TagIcon from "../../assets/Svgs/Portfolios/tag.svg";
import { Colors } from "../../shared/Colors";
import { goToExternalUrl } from "../../shared/utility";
import { portfoliosArray } from "./Description";

const Portfolios = (props) => {
  const [whichPortfolio, setWhichPortfolio] = useState(0);
  const [motion, setMotion] = useState(true);
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -320) {
      setMotion(false);
    } else if (currPos.y > -320) {
      setMotion(true);
    }
  });

  const goToExternalUrlHandler = (under_design) => {
    if (!under_design) {
      goToExternalUrl(`https://${portfoliosArray[whichPortfolio].address}`);
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (whichPortfolio > 0) {
  //       setWhichPortfolio(whichPortfolio - 1);
  //     }
  //     if (whichPortfolio < 2) {
  //       setWhichPortfolio(whichPortfolio + 1);
  //     }
  //   }, 6000);
  // });

  return (
    <PortfoliosWrapper
      id={props.id}
      disabled={portfoliosArray[whichPortfolio].under_design}
      motion={motion}
    >
      <p className="main-title disable-highlight">Portfolios</p>
      <div className="portfolios-slider">
        {portfoliosArray[whichPortfolio] && (
          <div className="portfolio-box">
            <div className="left-box">
              <p className="left-title disable-highlight">
                {portfoliosArray[whichPortfolio].name}
              </p>
              <div className="description-box disable-highlight">
                {portfoliosArray[whichPortfolio].description}
              </div>
              <p
                className="left-address disable-highlight"
                disabled={portfoliosArray[whichPortfolio].under_design}
                onClick={() =>
                  goToExternalUrlHandler(
                    portfoliosArray[whichPortfolio].under_design
                  )
                }
              >
                <img
                  src={TagIcon}
                  alt="tag"
                  className="left-tag disable-highlight"
                />
                {portfoliosArray[whichPortfolio].address}
              </p>
            </div>

            <div className="right-box">
              <div className="right-image-box">
                <img
                  src={portfoliosArray[whichPortfolio].image}
                  alt="shop"
                  className="right-image disable-highlight"
                />
                {portfoliosArray[whichPortfolio].under_design && (
                  <div className="under-design disable-highlight">
                    Under Design
                  </div>
                )}
              </div>
              <div className="right-button-box">
                <button
                  className="right-button portfolio disable-highlight"
                  onClick={() =>
                    goToExternalUrlHandler(
                      portfoliosArray[whichPortfolio].under_design
                    )
                  }
                  disabled={portfoliosArray[whichPortfolio].under_design}
                >
                  See this work sample
                </button>
                <button
                  className="right-button all-portfolios disable-highlight"
                  disabled={portfoliosArray[whichPortfolio].under_design}
                  onClick={() => (window.location.pathname = "/portfolios")}
                >
                  See all portfolios
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="portfolios-slider-button-box">
        {portfoliosArray.map((portfolio, index) => {
          return (
            <span
              key={index}
              className={[
                "portfolios-slider-button",
                "disable-highlight",
                `${index === whichPortfolio ? "wide-button" : ""}`,
              ].join(" ")}
              onClick={() => setWhichPortfolio(index)}
            ></span>
          );
        })}
      </div>
    </PortfoliosWrapper>
  );
};

const PortfoliosWrapper = styled.div`
  width: 80%;
  margin: 40px auto;
  font-family: "Comfortaa", cursive;

  .main-title {
    font-size: 30px;
    font-weight: 600;
    color: ${Colors.darkPurple};
    transition: 1000ms;
    opacity: ${(props) => (props.motion ? "0" : "1")};
  }
  .portfolios-slider {
    transition: 1000ms;
  }
  .portfolio-box {
    width: 100%;
    display: flex;
    align-items: enter;
    padding: 10px;
    overflow-x: hidden;
  }
  .left-box {
    width: 35%;
    position: relative;
    padding: 10px;
    transition: 1000ms ease;
    transform: ${(props) =>
      props.motion ? "translate(-1000px,0)" : "translate(0,0)"};
  }
  .right-box {
    width: 65%;
    position: relative;
    padding: 10px;
    transition: 1000ms ease;
    transform: ${(props) =>
      props.motion ? "translate(1000px,0)" : "translate(0,0)"};
  }

  .right-image-box {
    width: 100%;
    position: relative;
  }
  .right-image {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 10px #dddedf;
    filter: ${(props) => (props.disabled ? "grayscale(100%);" : "none")};
  }
  .under-design {
    position: absolute;
    top: 10px;
    right: -12px;
    padding: 2px 4px;
    background-color: ${Colors.red};
    color: ${Colors.light};
    border: 1px solid ${Colors.red};
    border-radius: 20px;
    box-shadow: 0 0 10px ${Colors.red};
    font-size: 10px;
    transform: rotate(45deg);
  }
  .right-button-box {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .right-button {
    padding: 10px 15px;
    margin: 10px;
    border-radius: 4px;
    outline: none;
    float: right;
    cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};
    transition: 200ms;
    :hover {
      box-shadow: 0 0 10px ${Colors.purple};
      transform: translate(0, -1px);
    }
  }
  .all-portfolios {
    color: ${Colors.purple};
    background-color: ${Colors.light};
    border: 2px solid ${Colors.purple};
  }
  .portfolio {
    color: ${Colors.light};
    background-color: ${Colors.purple};
    border: 2px solid ${Colors.purple};
  }

  .left-title {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    color: ${Colors.darkPurple};
  }
  .left-text {
    font-size: 16px;
    color: ${Colors.dark};
  }
  .left-freamwork {
    color: ${Colors.purple};
    margin: 0 4px;
    cursor: pointer;
  }
  .left-address {
    position: absolute;
    bottom: 20px;
    margin: 10px 0;
    font-size: 18px;
    cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};
    display: flex;
    align-items: center;
    text-decoration: underline;
  }
  .left-tag {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  .portfolios-slider-button-box {
    width: 100%;
    display: flex;
    margin: 10px 0;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .portfolios-slider-button {
    width: 10px;
    height: 10px;
    margin: 0 4px;
    border-radius: 50px;
    background-color: #dddedf;
    transition: 800ms;
    cursor: pointer;
  }
  .wide-button {
    width: 20px;
    background-color: ${Colors.purple};
    transition: 800ms;
  }

  //RESPANSIVE
  @media screen and (max-width: 1080px) {
    .left-title {
      font-size: 20px;
      margin: 5px 0;
    }
    .left-text {
      font-size: 12px;
      margin: 5px 0;
    }
    .left-address {
      font-size: 16px;
      margin: 5px 0;
    }
    .left-tag {
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 800px) {
    width: unset;
    padding: 10px;

    .main-title {
      font-size: 24px;
      text-align: center;
    }
    .portfolio-box {
      width: unset;
      display: block;
      padding: 0;
    }
    .left-box {
      width: unset;
    }
    .right-box {
      width: unset;
    }
    .description-box {
      height: 100px;
      margin-bottom: 10px;
    }
    .left-address {
      right: 20px;
      bottom: -15px;
      margin: 10px 0;
      font-size: 14px;
    }
    .right-button-box {
      justify-content: center;
    }
    .right-button {
      font-size: 12px;
    }
    .portfolios-slider-button-box {
      margin: 10px 0;
    }
    .portfolios-slider-button {
      width: 8px;
      height: 8px;
    }
    .wide-button {
      width: 16px;
    }
  }
  @media screen and (max-width: 500px) {
    .description-box {
      height: 130px;
    }
    .right-button {
      font-size: 10px;
      padding: 8px;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(Portfolios);
