import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import favicon from "./assets/Images/Header/favicon.ico";
import faviconImage from "./assets/Images/Header/apple-touch-icon.png";
import MainImage from "./assets/Images/main.jpg";
import { Helmet } from "react-helmet";
// import createSagaMiddleware from "redux-saga";
// import { applyMiddleware, combineReducers, compose, createStore } from "redux";
// import thunk from "redux-thunk";

// const composeEnhancers =
//   process.env.NODE_ENV === "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : null || compose;

// const rootReducer = combineReducers({
// store_name: reducer_name,
// });

// const sagaMiddleware = createSagaMiddleware();

// const store = createStore(
//   rootReducer
// composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
// );

// sagaMiddleware.run(sagas_name);

const app = (
  // <Provider store={store}>
  <BrowserRouter>
    <Helmet>
      <title>Codinative</title>
      <meta name="description" content="codinative is tomorrow's website" />
      <link rel="icon" href={favicon} />
      <link rel="apple-touch-icon" href={faviconImage} />
      <meta itemprop="name" content="Codinative" />
      <meta itemprop="description" content="codinative is tomorrow's website" />
      <meta itemprop="image" content={MainImage} />

      <meta property="og:url" content="https://codinative.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Codinative" />
      <meta
        property="og:description"
        content="codinative is tomorrow's website"
      />
      <meta property="og:image" content={MainImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Codinative" />
      <meta
        name="twitter:description"
        content="codinative is tomorrow's web site"
      />
      <meta name="twitter:image" content={MainImage} />
    </Helmet>
    <App />
  </BrowserRouter>
  // </Provider>
);
ReactDOM.render(
  <React.StrictMode>{app}</React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
