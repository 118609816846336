/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { browserName } from "react-device-detect";
import { Colors } from "../../shared/Colors";
import SocialMediaImage from "../../assets/Images/Contact/social-media.jpg";
import CallIcon from "../../assets/Svgs/Footer/call.svg";
import InstagramIcon from "../../assets/Svgs/Footer/instagram.svg";
import TelegramIcon from "../../assets/Svgs/Footer/telegram.svg";
import WhatsappIcon from "../../assets/Svgs/Footer/whatsapp.svg";
import EmailIcon from "../../assets/Svgs/Footer/email.svg";
import CallBackground from "../../assets/Svgs/Contact/call-background.svg";
import InstagramBackground from "../../assets/Svgs/Contact/instagram-background.svg";
import TelegramBackground from "../../assets/Svgs/Contact/telegram-background.svg";
import WhatsappBackground from "../../assets/Svgs/Contact/whatsapp-background.svg";

const Contact = (props) => {
  const [dontBeChrome, setDontBeChrome] = useState(false);
  useEffect(() => {
    if (browserName === "Chrome") {
      setDontBeChrome(true);
    }
  }, []);

  const [motion, setMotion] = useState(false);
  const [background] = useState(1);
  const [orderData, setOrderData] = useState({
    name: "",
    subject: "",
    massage: "",
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setMotion(true);
    setOrderData({
      name: "",
      subject: "",
      phone: "",
      massage: "",
    });
  }, []);

  // useEffect(() => {
  //   if (background > 4) {
  //     setBackground(1);
  //   }
  //   setTimeout(() => {
  //     setBackground(background + 1);
  //   }, 2000);
  // }, [background]);

  const gettingOrderData = (e) => {
    let inputId = e.target.id;
    let inputValue = e.target.value;
    Object.keys(orderData).map((item) => {
      if (item === inputId) {
        setOrderData((prevState) => ({
          ...prevState,
          [inputId]: inputValue,
        }));
      }
    });
  };

  const OrderDataHandler = (e) => {
    e.preventDefault();
    // console.log(orderData);
    //CALL_API
    setOrderData({
      name: "",
      subject: "",
      phone: "",
      massage: "",
    });
  };

  return (
    <ContactWrapper motion={motion} dontBeChrome={dontBeChrome}>
      <Helmet>
        <title>Codinative - content</title>
      </Helmet>

      <img
        src={
          background === 1
            ? InstagramBackground
            : background === 2
            ? TelegramBackground
            : background === 3
            ? WhatsappBackground
            : CallBackground
        }
        alt="icon"
        className="social-media-background"
      />
      <div className="contact-box">
        <div className="left-box">
          <p className="left-box-title disable-highlight">Send order to us</p>
          <form onSubmit={OrderDataHandler}>
            <div className="input-box">
              <p className="input-title disable-highlight">Subject</p>
              <input
                type="text"
                id="subject"
                placeholder="Insert subject"
                value={orderData.subject}
                onChange={gettingOrderData}
                className="input-field"
              />
            </div>
            <div className="small-field-box">
              <div className="small-mini-box">
                <div className="input-box">
                  <p className="input-title disable-highlight">Name</p>
                  <input
                    type="text"
                    id="name"
                    placeholder="Insert name"
                    value={orderData.name}
                    onChange={gettingOrderData}
                    className="input-field small-field"
                  />
                </div>
              </div>
              <div className="small-mini-box">
                <div className="input-box">
                  <p className="input-title disable-highlight">Phone</p>
                  <input
                    type="text"
                    id="phone"
                    placeholder="Insert phone"
                    value={orderData.phone}
                    onChange={gettingOrderData}
                    className="input-field small-field"
                  />
                </div>
              </div>
            </div>
            <div className="input-box">
              <p className="input-title disable-highlight">Massage</p>
              <textarea
                type="text"
                id="massage"
                placeholder="Your massage"
                value={orderData.massage}
                onChange={gettingOrderData}
                className="input-field textarea-height"
              />
            </div>
            <div className="input-box">
              <button type="submit" className="form-button disable-highlight">
                Send
              </button>
            </div>
          </form>
        </div>
        <div className="right-box">
          <div className="social-media-image-box">
            <img
              src={SocialMediaImage}
              alt="icon"
              className="social-media-image disable-highlight"
            />
          </div>

          <p className="right-box-title disable-highlight">
            Contact to us in social media
          </p>
          <div className="social-1">
            <div
              className="social-media-box"
              onClick={() => (window.location.href = "tel:+989028384749")}
            >
              <img
                src={CallIcon}
                alt="icon"
                className="social-media-icon disable-highlight"
              />
              <a>
                <p className="social-media-text disable-highlight">
                  +989028384749
                </p>
              </a>
            </div>
            <div
              className="social-media-box"
              onClick={() =>
                window.open("https://www.instagram.com/codinative/", "_blank")
              }
            >
              <img
                src={InstagramIcon}
                alt="icon"
                className="social-media-icon disable-highlight"
              />
              <a>
                <p className="social-media-text disable-highlight">
                  @codinative
                </p>
              </a>
            </div>
          </div>

          <div className="social-1">
            <div
              className="social-media-box"
              onClick={() => window.open("https://t.me/codinative/", "_blank")}
            >
              <img
                src={TelegramIcon}
                alt="icon"
                className="social-media-icon disable-highlight"
              />
              <a>
                <p className="social-media-text disable-highlight">
                  @codinative
                </p>
              </a>
            </div>
            <div
              className="social-media-box"
              onClick={() => (window.location.href = "tel:+989028384749")}
            >
              <img
                src={WhatsappIcon}
                alt="icon"
                className="social-media-icon disable-highlight"
              />
              <a>
                <p className="social-media-text disable-highlight">
                  +989028384749
                </p>
              </a>
            </div>
          </div>

          <div className="social-1">
            <div
              className="social-media-box social-1-email"
              onClick={() =>
                window.open("mailto:codinative@gmail.com", "_blank")
              }
            >
              <img
                src={EmailIcon}
                alt="icon"
                className="social-media-icon disable-highlight"
              />
              <a>
                <p className="social-media-text disable-highlight">
                  codinative@gmail.com
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ContactWrapper>
  );
};

const ContactWrapper = styled.div`
  font-family: "Comfortaa", cursive;
  margin: auto;
  padding: 100px 0;

  .social-media-background {
    width: 600px;
    height: 600px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${(props) => (props.motion ? "0.4" : "0")};
    z-index: -1;
    transition: 4s;
  }

  .contact-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-box {
    width: 50%;
    padding: 20px;
    overflow-x: hidden;
  }
  .right-box {
    width: 50%;
    padding: 20px;
    overflow-x: hidden;
  }

  .left-box-title {
    font-size: 25px;
    margin: 20px 0;
    text-align: center;
    color: ${Colors.darkPurple};
  }
  .small-field-box {
    display: flex;
    align-items: center;
  }
  .small-mini-box {
    width: 50%;
  }
  .input-box {
    padding: 10px;
    text-align: center;
  }
  .input-title {
    margin: 0 0 10px 0;
    font-size: 16px;
    color: ${Colors.darkPurple};
    transition: 1000ms;
    opacity: ${(props) => (props.motion ? "1" : "0")};
  }
  .input-field {
    width: 80%;
    height: 30px;
    border: 2px solid ${Colors.lightPurple};
    border-radius: 4px;
    outline: none;
    padding: 4px 8px;
    font-size: 14px;
    color: ${Colors.purple};
    transition: 1200ms;
    transform: ${(props) =>
      props.motion ? "translate(0, 0)" : "translate(-1000px, 0)"};
    opacity: ${(props) => (props.motion ? "1" : "0")};
    :focus {
      border: 2px solid ${Colors.purple};
      box-shadow: 0 0 2px;
    }
  }
  .small-field {
    width: 59%;
  }
  .textarea-height {
    min-width: 80%;
    max-width: 80%;
    min-height: 200px;
    max-height: 200px;
  }
  .form-button {
    width: 80%;
    border: 2px solid ${Colors.purple};
    border-radius: 4px;
    outline: none;
    padding: 8px;
    font-size: 16px;
    box-sizing: content-box;
    color: ${Colors.purple};
    background-color: ${Colors.light};
    cursor: ${(props) => (props.dontBeChrome ? "unset" : "pointer")};
    transition: 1000ms;
    opacity: ${(props) => (props.motion ? "1" : "0")};
    :hover {
      color: ${Colors.light};
      background-color: ${Colors.purple};
      box-shadow: 0 0 2px;
    }
  }

  .social-media-image-box {
    width: 100%;
    text-align: center;
  }
  .social-media-image {
    width: 80%;
    border-radius: 4px;
    box-shadow: 0 0 10px;
    transition: 1500ms;
    transform: ${(props) =>
      props.motion ? "translate(0, 0)" : "translate(0, -1000px)"};
    opacity: ${(props) => (props.motion ? "1" : "0")};
  }

  .right-box-title {
    margin: 20px 0;
    font-size: 25px;
    text-align: center;
    color: ${Colors.darkPurple};
    transition: 1500ms;
    transform: ${(props) =>
      props.motion ? "translate(0,0)" : "translate(1000px, 0)"};
    opacity: ${(props) => (props.motion ? "1" : "0")};
  }
  .social-1 {
    display: flex;
    justify-content: center;
    transition: 1500ms;
    transform: ${(props) =>
      props.motion ? "translate(0,0)" : "translate(1000px, 0)"};
    opacity: ${(props) => (props.motion ? "1" : "0")};
  }
  .social-media-box {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    cursor: ${(props) => (props.dontBeChrome ? "unset" : "pointer")};
    :hover {
      .social-media-icon {
        animation: rotating 100ms infinite;
      }
      .social-media-text {
        color: ${Colors.purple};
      }
    }
  }
  // .social-1-email {
  //   justify-content: center;
  // }
  .social-media-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  @keyframes rotating {
    0% {
      transform: rotate(20deg);
    }
    50% {
      transform: rotate(-20deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .social-media-text {
    margin: 0;
    font-size: 14px;
    color: ${Colors.darkPurple};
  }

  //RESPANSIVE
  @media screen and (max-width: 900px) {
    .social-media-background {
      width: 400px;
      height: 400px;
    }
    .social-media-text {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 700px) {
    .social-media-background {
      width: 400px;
      height: 400px;
    }
    .contact-box {
      width: 100%;
      display: block;
    }
    .left-box {
      width: unset;
    }
    .right-box {
      width: unset;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(Contact);
