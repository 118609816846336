/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../shared/Colors";
import { browserName } from "react-device-detect";
import TagIcon from "../../assets/Svgs/Portfolios/tag.svg";
import { goToExternalUrl } from "../../shared/utility";

const PortfoliosAtItself = (props) => {
  const [dontBeChrome, setDontBeChrome] = useState(false);
  useEffect(() => {
    if (browserName === "Chrome") {
      setDontBeChrome(true);
    }
  }, []);

  const [motion, setMotion] = useState(false);
  useEffect(() => {
    setMotion(true);
  }, []);

  const goToExternalUrlHandler = (under_design) => {
    if (!under_design) {
      goToExternalUrl(`https://${props.portfolio.address}`);
    }
  };

  return (
    <PortfoliosAtItselfWrapper
      motion={motion}
      direction={props.direction}
      under_design={props.portfolio.under_design}
      dontBeChrome={dontBeChrome}
    >
      <div className="portfolio">
        <div className="portfolio-left-box">
          <a>
            <p
              className="portfolio-title disable-highlight"
              onClick={() =>
                goToExternalUrlHandler(props.portfolio.under_design)
              }
            >
              {props.portfolio.name}
            </p>
          </a>
          <div className="portfolio-description disable-highlight">
            {props.portfolio.description}
          </div>
          <a>
            <div className="address-box">
              <img
                src={TagIcon}
                alt="tag"
                className="tag-icon disable-highlight"
              />
              <p
                className="portfolio-address disable-highlight"
                onClick={() =>
                  goToExternalUrlHandler(props.portfolio.under_design)
                }
              >
                {props.portfolio.address}
              </p>
            </div>
          </a>
        </div>
        <div className="portfolio-right-box">
          <img
            src={props.portfolio.image}
            alt="img"
            className="portfolio-image disable-highlight"
          />
          {props.portfolio.under_design && (
            <span className="under-design-text disable-highlight">
              Under design
            </span>
          )}
        </div>
      </div>
    </PortfoliosAtItselfWrapper>
  );
};

const PortfoliosAtItselfWrapper = styled.div`
  font-family: "Comfortaa", cursive;

  .portfolio {
    display: flex;
    flex-direction: ${(props) =>
      props.direction === "left" ? "unset" : "row-reverse"};
    box-sizing: border-box;
    margin: 50px 0;
    cursor: ${(props) => (props.under_design ? "no-drop" : "unset")};
    :hover {
      .portfolio-title {
        color: ${(props) =>
          props.under_design ? Colors.darkPurple : Colors.purple};
      }
    }
  }
  .portfolio-left-box {
    width: 60%;
    padding: 0 20px;
    transition: 2000ms;
    opacity: ${(props) => (props.motion ? "1" : "0")};
  }
  .portfolio-right-box {
    width: 40%;
    position: relative;
    transition: 2000ms;
    opacity: ${(props) => (props.motion ? "1" : "0")};
  }
  .portfolio-image {
    width: 100%;
    filter: ${(props) =>
      props.under_design ? "blur(2px) grayscale(1)" : "unset"};
    box-shadow: 0 0 10px;
  }
  .under-design-text {
    position: absolute;
    top: 15px;
    right: -20px;
    font-size: 14px;
    transform: rotate(45deg);
    background-color: ${Colors.red};
    color: ${Colors.light};
    border-radius: 20px;
    padding: 2px 5px;
  }

  .portfolio-title {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    cursor: ${(props) =>
      props.under_design
        ? "no-drop"
        : props.dontBeChrome
        ? "unset"
        : "pointer"};
    color: ${Colors.darkPurple};
  }
  .portfolio-description {
  }
  .left-text {
    margin: 8px 0;
    font-size: 15px;
    color: ${Colors.darkPurple};
  }
  .left-freamwork {
    margin: 0 4px;
    color: ${Colors.purple};
  }
  .address-box {
    display: flex;
    align-items: center;
  }
  .tag-icon {
    width: 20px;
    height: 20px;
  }
  .portfolio-address {
    margin: 10px 0;
    font-size: 14px;
    color: ${Colors.purple};
    cursor: ${(props) =>
      props.under_design
        ? "no-drop"
        : props.dontBeChrome
        ? "unset"
        : "pointer"};
  }

  //RESPANSIVE
  @media screen and (max-width: 1200px) {
    .portfolio-left-box {
      width: 50%;
    }
    .portfolio-right-box {
      width: 50%;
    }
    .portfolio-title {
      font-size: 18px;
    }
    .left-text {
      font-size: 12px;
    }
    .portfolio-address {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 1000px) {
    .portfolio-left-box {
      width: 30%;
    }
    .portfolio-right-box {
      width: 70%;
    }
    .portfolio-title {
      font-size: 18px;
    }
    .left-text {
      font-size: 12px;
    }
    .portfolio-address {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 950px) {
    .portfolio {
      flex-direction: column-reverse;
    }
    .portfolio-left-box {
      width: unset;
      padding: 10px 0;
    }
    .portfolio-right-box {
      width: unset;
    }
    .portfolio-title {
      font-size: 20px;
    }
    .left-text {
      font-size: 14px;
    }
    .portfolio-address {
      font-size: 16px;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(PortfoliosAtItself);
