import ShayanImage from "../../../assets/Svgs/About/shayan.svg";
import HessamImage from "../../../assets/Svgs/About/hessam.svg";
import HabibImage from "../../../assets/Svgs/About/habib.svg";
import BackendIcon from "../../../assets/Svgs/About/backend.svg";
import FrontendIcon from "../../../assets/Svgs/About/frontend.svg";
import IdeaIcon from "../../../assets/Svgs/About/idea.svg";
import DevelopIcon from "../../../assets/Svgs/About/development.svg";

export const PersonsArray = [
  {
    image: ShayanImage,
    name: "Shayan",
    description:
      "Front-end and Back-end Developer. start developing 3 years ago.",
    type: "Main member",
    birthday: "25 Apr 1998",
    abilities: [BackendIcon, FrontendIcon, IdeaIcon, DevelopIcon],
  },
  {
    image: HessamImage,
    name: "Hessam",
    description: "Back-end Developer. start developing 2 years ago.",
    type: "Main member",
    birthday: "28 Dec 1997",
    abilities: [BackendIcon, FrontendIcon, IdeaIcon, DevelopIcon],
  },
  {
    image: HabibImage,
    name: "Mohammad",
    description:
      "Front-end and Back-end Developer. start developing 3 years ago.",
    type: "Main member",
    birthday: "4 Jul 1998",
    abilities: [BackendIcon, FrontendIcon, IdeaIcon, DevelopIcon],
  },
];
