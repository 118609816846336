import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GangesterImage from "../../../assets/Svgs/About/gangester.svg";
import PolicemanImage from "../../../assets/Svgs/About/policeman.svg";
import DeleteIcon from "../../../assets/Svgs/About/delete.svg";
import { Colors } from "../../../shared/Colors";

const Skills = (props) => {
  const [motion, setMotion] = useState(false);

  useEffect(() => {
    setMotion(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <SkillsWrapper motion={motion}>
      <div className="skill-box">
        <div className="left-box">
          <div className="left">
            <p className="left-title"> Security</p>
            <p className="left-text">is a very important issue</p>
          </div>
          <div className="right">
            <img src={PolicemanImage} alt="skill" className="skill-icon" />
          </div>
        </div>
        <div className="right-box">
          <div className="left">
            <p className="right-text">
              We make your site safe from all script attacks and damage that
              hackers can do to your site. Leave your site design to us
            </p>
          </div>
          <div className="right">
            <img src={GangesterImage} alt="skill" className="skill-icon" />
            <img src={DeleteIcon} alt="skill" className="delete-icon" />
          </div>
        </div>
      </div>
    </SkillsWrapper>
  );
};

const SkillsWrapper = styled.div`
  margin-bottom: 80px;

  .skill-box {
    display: flex;
    align-items: center;
  }
  .left-box {
    width: 50%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .right-box {
    position: relative;
    width: 50%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left-title {
    color: ${Colors.darkPurple};
    font-size: 36px;
    margin: 0;
  }
  .left-text {
    color: ${Colors.darkPurple};
    font-size: 20px;
    margin: 0;
  }
  .right-text {
    color: ${Colors.darkPurple};
    font-size: 20px;
    margin: 0;
  }

  .skill-icon {
    width: 100px;
    height: 100px;
  }
  .delete-icon {
    position: absolute;
    right: 40px;
    top: 12px;
    width: 60px;
    height: 60px;
  }

  //RESPONSIVE
  @media screen and (max-width: 1000px) {
    .skill-box {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .left-box {
      width: 100%;
    }
    .right-box {
      width: 100%;
    }
  }
  @media screen and (max-width: 550px) {
    .left-title {
      font-size: 28px;
    }
    .left-text {
      font-size: 18px;
    }
    .right-text {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 450px) {
    .left-title {
      font-size: 28px;
    }
    .left-text {
      font-size: 16px;
    }
    .right-text {
      font-size: 14px;
    }
  }
  //RESPONSIVE
`;

export default React.memo(Skills);
