/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../shared/Colors";
import CodeIcon from "../../assets/Svgs/Layout/code-logo.svg";
import CircleIcon from "../../assets/Svgs/Footer/circle.svg";
import TelegramIcon from "../../assets/Svgs/Footer/telegram.svg";
import InstagramIcon from "../../assets/Svgs/Footer/instagram.svg";
import WhatsappIcon from "../../assets/Svgs/Footer/whatsapp.svg";
import EmailIcon from "../../assets/Svgs/Footer/email.svg";
import FootersFlash from "../Svgs/FootersFlash/FootersFlash";

const Footer = (props) => {
  const [getUpFooter, setGetUpFooter] = useState(false);
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    let Timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => clearInterval(Timer);
  }, []);
  let hours = date.getHours();
  let minutes = date.getMinutes();

  return (
    <FooterWrapper getUpFooter={getUpFooter} dontBeChrome={props.dontBeChrome}>
      <FootersFlash
        properties={{ position: "absolute", top: "240px", left: "49%" }}
        getUpFooter={getUpFooter}
      />
      <div className="footer-box">
        <div className="top-box">
          <span className="top-codinative-title disable-highlight">
            Codinative
          </span>
          <a>
            <img
              src={CircleIcon}
              alt="circle"
              className="top-circle-icon disable-highlight"
              onClick={() => setGetUpFooter(!getUpFooter)}
            />
          </a>
          <span className="top-time-title disable-highlight">
            Tehran {hours > 9 ? hours : `0${hours}`}:
            {minutes > 9 ? minutes : `0${minutes}`}
          </span>
        </div>
        <div className="bottom-box">
          <div className="bottom-left-box">
            <img
              src={CodeIcon}
              alt="code"
              className="bottom-codinative-icon disable-highlight"
            />
            <p className="bottom-codinative-text disable-highlight">
              Codinative
            </p>
          </div>
          <div className="bottom-middle-box">
            <p
              className="bottom-middle-text disable-highlight"
              onClick={() => (window.location.pathname = "/portfolios")}
            >
              Portfolios
            </p>
            <p
              className="bottom-middle-text disable-highlight"
              onClick={() => (window.location.pathname = "/")}
            >
              Properties
            </p>
            <p
              className="bottom-middle-text disable-highlight"
              onClick={() => (window.location.pathname = "/")}
            >
              Blogs
            </p>
            <p
              className="bottom-middle-text disable-highlight"
              onClick={() => (window.location.pathname = "/about")}
            >
              About
            </p>
          </div>
          <div className="bottom-right-box">
            <img
              src={TelegramIcon}
              alt="code"
              className="bottom-right-icon disable-highlight"
              onClick={() => window.open("https://t.me/codinative/", "_blank")}
            />
            <img
              src={InstagramIcon}
              alt="code"
              className="bottom-right-icon disable-highlight"
              onClick={() =>
                window.open("https://instagram.com/codinative/", "_blank")
              }
            />
            <img
              src={WhatsappIcon}
              alt="code"
              className="bottom-right-icon disable-highlight"
              onClick={() => (window.location.href = "tel:+989028384749")}
            />
            <img
              src={EmailIcon}
              alt="mail"
              className="bottom-right-icon disable-highlight"
              onClick={() =>
                window.open("mailto:codinative@gmail.com", "_blank")
              }
            />
          </div>
        </div>
        <div className="belongs-to-box">
          <p className="belongs-to-codinative-text disable-highlight">
            &copy; All material and intellectual property rights belong to
            Codinative
          </p>
        </div>
      </div>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 375px;
  overflow: hidden;
  font-family: "Comfortaa", cursive;

  .footer-box {
    width: 100%;
    height: 375px;
    position: absolute;
    transition: 1000ms;
    top: ${(props) => (props.getUpFooter ? "120px" : "320px")};
  }

  .top-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  .bottom-box {
    width: 80%;
    // height: 20vh;
    margin: auto;
    display: flex;
    align-items: center;
  }
  .top-codinative-title {
    color: ${Colors.purple};
  }
  .top-time-title {
    color: ${Colors.purple};
  }
  .top-circle-icon {
    width: 60px;
    height: 60px;
    margin: 0 10px;
    cursor: ${(props) => (props.dontBeChrome ? "unset" : "pointer")};
  }

  .bottom-left-box {
    width: 25%;
    text-align: center;
    display: block;
  }
  .bottom-codinative-icon {
    width: 50px;
    height: 50px;
  }
  .bottom-codinative-text {
    color: ${Colors.darkPurple};
    font-size: 20px;
  }

  .bottom-middle-box {
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .bottom-middle-text {
    font-size: 16px;
    color: ${Colors.darkPurple};
    cursor: ${(props) => (props.dontBeChrome ? "unset" : "pointer")};
    transition: 400ms;
    :hover {
      transform: scale(1.08, 1.08);
      color: ${Colors.purple};
    }
  }

  .bottom-right-box {
    width: 25%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bottom-right-icon {
    width: 20px;
    height: 20px;
    margin: 10px;
    cursor: ${(props) => (props.dontBeChrome ? "unset" : "pointer")};

    :hover {
      animation: rotating 100ms infinite;
    }
  }
  @keyframes rotating {
    0% {
      transform: rotate(20deg);
    }
    50% {
      transform: rotate(-20deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .belongs-to-box {
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
  .belongs-to-codinative-text {
    font-size: 14px;
    margin: 0;
  }

  //RESPANSIVE
  @media screen and (max-width: 740px) {
    .footer-box {
      top: ${(props) => (props.getUpFooter ? "80px" : "320px")};
    }
    .bottom-middle-text {
      font-size: 12px;
    }
    .belongs-to-codinative-text {
      font-size: 10px;
    }
    .bottom-box {
      display: block;
      // height: 25vh;
      margin-top: 20px;
    }
    .bottom-left-box {
      width: 100%;
    }
    .bottom-middle-box {
      width: 100%;
    }
    .bottom-right-box {
      width: 100%;
    }
    .bottom-codinative-text {
      margin: 0;
    }
    .belongs-to-box {
      margin-top: 20px;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(Footer);
