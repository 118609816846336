import React, { useEffect } from "react";
import styled from "styled-components";
import Portfolios from "../../components/Portfolios/Portfolios";
import Properties from "../../components/Properties/Properties";
import Blogs from "../../components/Blogs/Blogs";
import Beginning from "../../components/Beginning/Beginning";
import CodinativeBox from "../../components/CodinativeBox/CodinativeBox";
import { Helmet } from "react-helmet";
import CodinativeCard from "../../components/CodinativeCard/CodinativeCard";

const Home = (props) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <HomeWrapper>
      <Helmet>
        <title>Codinative</title>
      </Helmet>
      <Helmet title="Codinative" />

      <Beginning id="codinative" />
      <Portfolios id="portfolios" />
      <Properties id="properties" />
      <CodinativeCard />
      <Blogs id="blogs" />
      <CodinativeBox />
    </HomeWrapper>
  );
};

const HomeWrapper = styled.div``;

export default React.memo(Home);
