import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Colors } from "../../shared/Colors";
import InfoImage from "../../assets/Svgs/About/information.svg";
import CodeImage from "../../assets/Svgs/Layout/code-logo.svg";
import AIcon from "../../assets/Svgs/About/coffee.svg";
import BIcon from "../../assets/Svgs/About/programming-language.svg";
import CIcon from "../../assets/Svgs/About/aim.svg";
import DIcon from "../../assets/Svgs/About/eye.svg";
import Person from "../../components/About/Person/Person";
import Clock from "../../components/About/Clock/Clock";
import Skills from "../../components/About/Skills/Skills";

const About = (props) => {
  const [motion, setMotion] = useState(false);

  useEffect(() => {
    setMotion(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <AboutWrapper motion={motion}>
      <Helmet>
        <title>Codinative - about</title>
      </Helmet>

      <div className="about-box">
        <img src={InfoImage} alt="info" className="info-image" />
        <p className="about-box-title disable-highlight">Codinative</p>
        <div className="top-box">
          <div className="top-box-left">
            <img
              src={CodeImage}
              alt="code"
              className="code-image disable-highlight"
            />
          </div>
          <div className="top-box-right">
            <div className="about-text-box">
              <p className="about-text-title disable-highlight">Codinative</p>
              <p className="about-text-text disable-highlight">
                The Codinative team is one of the most reputable and successful
                team active in the field of site design. This honor is the
                result of the efforts of our creative and committed team, as
                well as the trust of our dear customers. We are tenacious,
                disciplined and we are creative! just trust us!!
              </p>
              <div className="icons-box disable-highlight">
                <img src={AIcon} alt="i" className="icons disable-highlight" />_
                <img src={BIcon} alt="i" className="icons disable-highlight" />_
                <img src={CIcon} alt="i" className="icons disable-highlight" />_
                <img src={DIcon} alt="i" className="icons disable-highlight" />
              </div>
            </div>
          </div>
        </div>

        <Clock />
        <Skills />
        <Person />
      </div>
    </AboutWrapper>
  );
};

const AboutWrapper = styled.div`
  width: 80%;
  margin: auto;
  padding: 100px 0;
  font-family: "Comfortaa", cursive;

  .info-image {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 600px;
    height: 600px;
    transition: 2000ms;
    opacity: ${(props) => (props.motion ? "0.3" : "0")};
    transform: translate(-50%, -50%);
  }

  .about-box-title {
    margin: 20px 0;
    text-align: center;
    font-size: 50px;
    transition: 2000ms;
    letter-spacing: ${(props) => (props.motion ? "0" : "30px")};
    color: ${Colors.darkPurple};
  }

  .top-box {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bottom-box {
  }

  .top-box-left {
    width: 50%;
  }
  .code-image {
    width: 100px;
    height: 100px;
    transition: 2000ms ease;
    opacity: ${(props) => (props.motion ? "1" : "0")};
  }
  .top-box-right {
    width: 50%;
  }
  .about-text-box {
    width: 80%;
    border-radius: 4px;
    padding: 10px;
    float: right;
    transition: 2000ms ease;
    opacity: ${(props) => (props.motion ? "1" : "0")};
    background-color: ${(props) =>
      props.motion ? "rgba(120, 19, 254, 0.1)" : "rgba(120, 19, 254, 0)"};
    box-shadow: 0 0 10px
      ${(props) =>
        props.motion ? "rgba(120, 19, 254, 0.3)" : "rgba(120, 19, 254, 0)"};
  }
  .about-text-title {
    margin: 5px;
    color: ${Colors.darkPurple};
    text-align: center;
    font-size: 20px;
  }
  .about-text-text {
    margin: 0;
    color: ${Colors.darkPurple};
    text-align: center;
    font-size: 14px;
  }
  .icons-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0;
  }
  .icons {
    width: 30px;
    height: 30px;
  }

  //RESPANSIVE
  @media screen and (max-width: 700px) {
    .info-image {
      width: 300px;
      height: 300px;
    }
    .about-box-title {
      font-size: 30px;
      letter-spacing: ${(props) => (props.motion ? "0" : "10px")};
    }
    .about-text-text {
      font-size: 12px;
    }
    .icons {
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 600px) {
    .top-box {
      display: block;
    }
    .top-box-left {
      width: 100%;
      text-align: center;
    }
    .top-box-right {
      width: 100%;
      text-align: center;
    }
    .about-text-box {
      width: unset;
      float: none;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(About);
