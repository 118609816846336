import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { portfoliosArray } from "../../components/Portfolios/Description";
import PortfoliosAtItself from "../../components/Portfolios/PortfoliosAtItself";
import FolderImage from "../../assets/Svgs/Portfolios/folder.svg";
import { Colors } from "../../shared/Colors";
import { Helmet } from "react-helmet";

const Portfolios = (props) => {
  const [motion, setMotion] = useState(false);

  useEffect(() => {
    setMotion(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <PortfoliosWrapper motion={motion}>
      <Helmet>
        <title>Codinative - portfolios</title>
      </Helmet>

      <img
        src={FolderImage}
        alt="folder"
        className="folder-icon disable-highlight"
      />
      <div className="portfolios-box">
        <p className="portfolios-box-title disable-highlight">Portfolios</p>
        <div className="portfolios">
          {portfoliosArray.map((portfolio, index) => {
            if (index === 0 || index === 2) {
              return (
                <PortfoliosAtItself
                  key={portfolio.name}
                  portfolio={portfolio}
                  direction="right"
                />
              );
            } else {
              return (
                <PortfoliosAtItself
                  key={portfolio.name}
                  portfolio={portfolio}
                  direction="left"
                />
              );
            }
          })}
        </div>
      </div>
    </PortfoliosWrapper>
  );
};

const PortfoliosWrapper = styled.div`
  font-family: "Comfortaa", cursive;
  width: 80%;
  margin: auto;
  padding: 100px 0;

  .folder-icon {
    width: 600px;
    height: 600px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${(props) => (props.motion ? "0.4" : "0")};
    z-index: -1;
    transition: 4s;
  }

  .portfolios-box {
    display: block;
  }
  .portfolios-box-title {
    margin: 40px 0;
    font-size: 28px;
    color: ${Colors.darkPurple};
  }
  .portfolios {
    width: 100%;
  }

  //RESPANSIVE
  @media screen and (max-width: 900px) {
    .folder-icon {
      width: 400px;
      height: 400px;
    }
  }
  @media screen and (max-width: 700px) {
    .folder-icon {
      width: 400px;
      height: 400px;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(Portfolios);
