import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CodeSvg from "../../components/Svgs/CodeSvg/CodeSvg";
import MainImage from "../../assets/Images/main.jpg";
import { Colors } from "../../shared/Colors";

const Beginning = (props) => {
  const [objectFit, setObjectFit] = useState(true);
  useEffect(() => {
    if (window.innerWidth < 500) {
      setObjectFit(false);
    }
  }, []);

  return (
    <BeginningWrapper objectFit={objectFit} id={props.id}>
      <div className="main-image-box">
        <img
          src={MainImage}
          alt="main-img"
          className="main-image disable-highlight"
        />
        <CodeSvg
          properties={{ position: "absolute", top: "70%", left: "5%" }}
        />
        <div className="main-text">
          <p className="top-text disable-highlight">Welcome to Codinative, </p>
          <p className="basic-text disable-highlight">
            Codinative is tomorrow's
            <br />
            Website
          </p>
          <p className="bottom-text disable-highlight">
            The Codinative team is one of the most reputable and successful team
            active in the field of site design. This honor is the result of the
            efforts of our creative and committed team, as well as the trust of
            our dear customers. We are tenacious, disciplined and we are
            creative! just trust us!!
          </p>
        </div>
      </div>
    </BeginningWrapper>
  );
};

const BeginningWrapper = styled.div`
  font-family: "Comfortaa", cursive;

  .main-image-box {
    overflow-x: ${(props) => (props.objectFit ? "unset" : "hidden")};
    width: 100%;
    position: relative;
  }
  .main-image {
    width: ${(props) => (props.objectFit ? "100%" : "auto")};
    height: 100vh;
    position: relative;
    top: 0;
    object-fit: ${(props) => (props.objectFit ? "cover" : "fill")};
    filter: brightness(0.5);
  }
  .main-text {
    width: 80%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: ${Colors.light};
  }
  .top-text {
    font-size: 22px;
    margin: 10px 0;
  }
  .basic-text {
    font-size: 50px;
  }
  .bottom-text {
    font-size: 18px;
    margin: 10px 0;
  }

  //RESPANSIVE
  @media screen and (max-width: 720px) {
    .top-text {
      font-size: 16px;
    }
    .basic-text {
      font-size: 40px;
    }
    .bottom-text {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 565px) {
    .top-text {
      font-size: 16px;
    }
    .basic-text {
      font-size: 32px;
    }
    .bottom-text {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 460px) {
    .top-text {
      font-size: 14px;
    }
    .basic-text {
      font-size: 26px;
    }
    .bottom-text {
      font-size: 12px;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(Beginning);
