import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import React, { useState } from "react";
import styled from "styled-components";
import GoUpIcon from "../../assets/Svgs/Layout/goUp.svg";

const GoUp = (props) => {
  const [motion, setMotion] = useState(true);
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -320) {
      setMotion(false);
    } else if (currPos.y > -320) {
      setMotion(true);
    }
  });

  return (
    <GoUpWrapper
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }
      motion={motion}
    >
      <div className="goup-box">
        <img
          src={GoUpIcon}
          alt="goUp"
          className="go-up-icon disable-highlight"
        />
      </div>
    </GoUpWrapper>
  );
};

const GoUpWrapper = styled.a`
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 9;
  transition: 1000ms;
  opacity: ${(props) => (props.motion ? "0" : "1")};
  transform: ${(props) =>
    props.motion ? "scale(0.0001,0.0001)" : "scale(1,1)"};
  :hover {
    transform: scale(1.1, 1.1);
  }
  .go-up-icon {
    width: 50px;
    height: 50px;
  }

  //RESPANSIVE
  @media screen and (max-width: 620px) {
    bottom: 20px;
    right: 20px;

    .go-up-icon {
      width: 30px;
      height: 30px;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(GoUp);
