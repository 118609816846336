import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../shared/Colors";
import { PersonsArray } from "./Description";
import MemberIcon from "../../../assets/Svgs/About/membership.svg";
import BirthdayIcon from "../../../assets/Svgs/About/confetti.svg";

const Person = (props) => {
  const [motion, setMotion] = useState(false);

  useEffect(() => {
    setMotion(true);
  }, []);

  return (
    <PersonWrapper motion={motion}>
      <p className="intro-box-title disable-highlight">Team members</p>
      <div className="intro-box">
        {PersonsArray.map((person) => {
          return (
            <div className="intro-person" key={person.name}>
              <img
                src={person.image}
                alt="person"
                className="person-image disable-highlight"
              />
              <p className="person-name disable-highlight">{person.name}</p>
              <p className="about-person disable-highlight">
                {person.description}
              </p>
              <div className="person-detail-box">
                <div className="person-details disable-highlight">
                  <img
                    src={MemberIcon}
                    alt="member"
                    className="detail-icon disable-highlight"
                  />{" "}
                  {person.type}
                </div>
                <div className="person-details  disable-highlight">
                  <img
                    src={BirthdayIcon}
                    alt="member"
                    className="detail-icon disable-highlight"
                  />{" "}
                  {person.birthday}
                </div>
              </div>
              <div className="abilities-box">
                {person.abilities[0] ? (
                  <img
                    src={person.abilities[0]}
                    alt="icon"
                    className="abilities disable-highlight"
                  />
                ) : null}
                {person.abilities[1] ? (
                  <img
                    src={person.abilities[1]}
                    alt="icon"
                    className="abilities disable-highlight"
                  />
                ) : null}
                {person.abilities[2] ? (
                  <img
                    src={person.abilities[2]}
                    alt="icon"
                    className="abilities disable-highlight"
                  />
                ) : null}
                {person.abilities[3] ? (
                  <img
                    src={person.abilities[3]}
                    alt="icon"
                    className="abilities disable-highlight"
                  />
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </PersonWrapper>
  );
};

const PersonWrapper = styled.div`
font-family: 'Comfortaa', cursive;

.intro-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .intro-box-title{
    font-size: 28px;
    text-align: center;
    color: ${Colors.darkPurple};
  }
  .intro-person {
    width: 25%;
    margin: 10px;
    padding: 10px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: ${Colors.light};
    box-shadow: 0 0 10px #dddedf;
    transition: 1000ms;
    transform: ${(props) => (props.motion ? "skewY(0)" : "skewY(10deg)")};
  }
  .person-image {
    width: 100px;
    height: 100px;
  }
  .person-name {
    margin: 10px 0;
    font-size: 20px;
    color${Colors.darkPurple}:
  }
  .about-person {
    font-size: 16px;
    color${Colors.darkPurple}:
  }
  .person-details-box{
    width: 100%;
    display: block;
  }
  .person-details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    font-size: 14px;
    color: ${Colors.darkPurple};
  }
  .detail-icon{
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .abilities-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }
  .abilities{
    width: 25px;
    height: 25px;
  }


  //RESPANSIVE
  @media screen and (max-width: 1100px) {
    .person-image {
      width: 80px;
      height: 80px;
    }
    .person-name {
      font-size: 18px;
    }
    .about-person {
      font-size: 14px;
      color${Colors.darkPurple}:
    }
    .person-details{
      font-size: 12px;
    }
    .detail-icon{
      width: 15px;
      height: 15px;
    }
    .abilities{
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-width: 900px) {
    .intro-box {
      width: 100%;
      display: block;
    }
    .intro-person {
      width: 60%;
      margin: 10px auto;
    }
    .person-image {
      width: 100px;
      height: 100px;
    }
    .person-name {
      font-size: 20px;
    }
    .about-person {
      font-size: 16px;
      color${Colors.darkPurple}:
    }
    .person-details{
      font-size: 12px;
    }
    .detail-icon{
      width: 20px;
      height: 20px;
    }
    .abilities{
      width: 25px;
      height: 25px;
    }
  }
  @media screen and (max-width: 550px) {
    .person-image {
      width: 80px;
      height: 80px;
    }
    .person-name {
      font-size: 18px;
    }
    .about-person {
      font-size: 14px;
      color${Colors.darkPurple}:
    }
    .person-details{
      font-size: 12px;
    }
    .detail-icon{
      width: 15px;
      height: 15px;
    }
    .abilities{
      width: 20px;
      height: 20px;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(Person);
