import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../shared/Colors";

const CodeSvg = (props) => {
  const [location, setLocation] = useState();
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y > -10) {
      setLocation("45,25");
    } else if (currPos.y > -14) {
      setLocation("45,25 42,28");
    } else if (currPos.y > -18) {
      setLocation("45,25 39,31");
    } else if (currPos.y > -22) {
      setLocation("45,25 36,34");
    } else if (currPos.y > -26) {
      setLocation("45,25 33,37");
    } else if (currPos.y > -30) {
      setLocation("45,25 30,40");
    } else if (currPos.y > -34) {
      setLocation("45,25 27,43");
    } else if (currPos.y > -38) {
      setLocation("45,25 24,46");
    } else if (currPos.y > -42) {
      setLocation("45,25 21,49");
    } else if (currPos.y > -46) {
      setLocation("45,25 18,52");
    } else if (currPos.y > -50) {
      setLocation("45,25 15,55");
    } else if (currPos.y > -54) {
      setLocation("45,25 12,58");
    } else if (currPos.y > -58) {
      setLocation("45,25 9,61");
    } else if (currPos.y > -62) {
      setLocation("45,25 6,64");
    } else if (currPos.y > -66) {
      setLocation("45,25 5,65");
    } else if (currPos.y > -70) {
      setLocation("45,25 5,65 8,68");
    } else if (currPos.y > -74) {
      setLocation("45,25 5,65 11,71");
    } else if (currPos.y > -78) {
      setLocation("45,25 5,65 14,74");
    } else if (currPos.y > -82) {
      setLocation("45,25 5,65 17,77");
    } else if (currPos.y > -86) {
      setLocation("45,25 5,65 20,80");
    } else if (currPos.y > -90) {
      setLocation("45,25 5,65 23,83");
    } else if (currPos.y > -94) {
      setLocation("45,25 5,65 26,86");
    } else if (currPos.y > -98) {
      setLocation("45,25 5,65 29,89");
    } else if (currPos.y > -102) {
      setLocation("45,25 5,65 32,92");
    } else if (currPos.y > -106) {
      setLocation("45,25 5,65 35,95");
    } else if (currPos.y > -110) {
      setLocation("45,25 5,65 38,98");
    } else if (currPos.y > -114) {
      setLocation("45,25 5,65 41,101");
    } else if (currPos.y > -118) {
      setLocation("45,25 5,65 43,103");
    } else if (currPos.y > -122) {
      setLocation("45,25 5,65 44,104");
    } else if (currPos.y > -126) {
      setLocation("45,25 5,65 45,105");
    } else if (currPos.y > -130) {
      setLocation("45,25 5,65  45,105  45,105");
    } else if (currPos.y > -134) {
      setLocation("45,25 5,65  45,105  45,106");
    } else if (currPos.y > -138) {
      setLocation("45,25 5,65  45,105  45,108");
    } else if (currPos.y > -142) {
      setLocation("45,25 5,65  45,105  45,109");
    } else if (currPos.y > -146) {
      setLocation("45,25 5,65  45,105  45,111");
    } else if (currPos.y > -150) {
      setLocation("45,25 5,65  45,105  45,112");
    } else if (currPos.y > -154) {
      setLocation("45,25 5,65  45,105  45,114");
    } else if (currPos.y > -158) {
      setLocation("45,25 5,65  45,105  45,115");
    } else if (currPos.y > -162) {
      setLocation("45,25 5,65  45,105  45,117");
    } else if (currPos.y > -166) {
      setLocation("45,25 5,65  45,105  45,118");
    } else if (currPos.y > -170) {
      setLocation("45,25 5,65  45,105  45,120");
    } else if (currPos.y > -174) {
      setLocation("45,25 5,65  45,105  45,121");
    } else if (currPos.y > -178) {
      setLocation("45,25 5,65  45,105  45,123");
    } else if (currPos.y > -182) {
      setLocation("45,25 5,65  45,105  45,124");
    } else if (currPos.y > -186) {
      setLocation("45,25 5,65  45,105  45,125");
    } else if (currPos.y > -190) {
      setLocation("45,25 5,65  45,105  45,125  49,117");
    } else if (currPos.y > -194) {
      setLocation("45,25 5,65  45,105  45,125  53,109");
    } else if (currPos.y > -198) {
      setLocation("45,25 5,65  45,105  45,125  57,101");
    } else if (currPos.y > -202) {
      setLocation("45,25 5,65  45,105  45,125  61,93");
    } else if (currPos.y > -206) {
      setLocation("45,25 5,65  45,105  45,125  65,85");
    } else if (currPos.y > -210) {
      setLocation("45,25 5,65  45,105  45,125  69,77");
    } else if (currPos.y > -214) {
      setLocation("45,25 5,65  45,105  45,125  73,69");
    } else if (currPos.y > -218) {
      setLocation("45,25 5,65  45,105  45,125  77,61");
    } else if (currPos.y > -222) {
      setLocation("45,25 5,65  45,105  45,125  81,53");
    } else if (currPos.y > -226) {
      setLocation("45,25 5,65  45,105  45,125  85,45");
    } else if (currPos.y > -230) {
      setLocation("45,25 5,65  45,105  45,125  89,37");
    } else if (currPos.y > -234) {
      setLocation("45,25 5,65  45,105  45,125  93,29");
    } else if (currPos.y > -238) {
      setLocation("45,25 5,65  45,105  45,125  97,21");
    } else if (currPos.y > -242) {
      setLocation("45,25 5,65  45,105  45,125  101,13");
    } else if (currPos.y > -246) {
      setLocation("45,25 5,65  45,105  45,125  105,5");
    } else if (currPos.y > -250) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,6");
    } else if (currPos.y > -254) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,8");
    } else if (currPos.y > -258) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,9");
    } else if (currPos.y > -262) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,11");
    } else if (currPos.y > -266) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,12");
    } else if (currPos.y > -270) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,14");
    } else if (currPos.y > -274) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,15");
    } else if (currPos.y > -278) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,17");
    } else if (currPos.y > -282) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,18");
    } else if (currPos.y > -286) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,20");
    } else if (currPos.y > -290) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,21");
    } else if (currPos.y > -294) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,22");
    } else if (currPos.y > -298) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,23");
    } else if (currPos.y > -302) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,24");
    } else if (currPos.y > -306) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25");
    } else if (currPos.y > -310) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  108,28");
    } else if (currPos.y > -314) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  111,31");
    } else if (currPos.y > -318) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  114,34");
    } else if (currPos.y > -322) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  117,37");
    } else if (currPos.y > -326) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  120,40");
    } else if (currPos.y > -330) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  123,43");
    } else if (currPos.y > -334) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  126,46");
    } else if (currPos.y > -338) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  129,49");
    } else if (currPos.y > -342) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  132,52");
    } else if (currPos.y > -346) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  135,55");
    } else if (currPos.y > -350) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  138,58");
    } else if (currPos.y > -354) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  141,61");
    } else if (currPos.y > -358) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  143,63");
    } else if (currPos.y > -362) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  144,64");
    } else if (currPos.y > -366) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65");
    } else if (currPos.y > -370) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  142,68");
    } else if (currPos.y > -374) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  139,71");
    } else if (currPos.y > -378) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  136,74");
    } else if (currPos.y > -382) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  133,77");
    } else if (currPos.y > -386) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  130,80");
    } else if (currPos.y > -390) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  127,83");
    } else if (currPos.y > -394) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  124,86");
    } else if (currPos.y > -398) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  121,89");
    } else if (currPos.y > -402) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  118,92");
    } else if (currPos.y > -406) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  115,95");
    } else if (currPos.y > -410) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  112,98");
    } else if (currPos.y > -414) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  109,101");
    } else if (currPos.y > -418) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  107,103");
    } else if (currPos.y > -422) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  106,104");
    } else if (currPos.y > -426) {
      setLocation("45,25 5,65  45,105  45,125  105,5  105,25  145,65  105,105");
    }
  });

  return (
    <CodeSvgWrapper properties={props.properties}>
      <svg width="160" height="140">
        <polyline points={location} className="line" />
        Sorry, your browser does not support inline SVG.
      </svg>
    </CodeSvgWrapper>
  );
};

const CodeSvgWrapper = styled.div`
  position: ${(props) => props.properties.position};
  top: ${(props) => props.properties.top};
  left: ${(props) => props.properties.left};

  .line {
    fill: transparent;
    stroke: ${(props) => props.properties.color ?? Colors.light};
    stroke-width: 4;
  }
`;

export default CodeSvg;
