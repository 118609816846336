import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StairImage from "../../assets/Images/code-1.jpg";
import { Colors } from "../../shared/Colors";

const CodinativeBox = (props) => {
  const [objectFit, setObjectFit] = useState(true);
  useEffect(() => {
    if (window.innerWidth < 450) {
      setObjectFit(false);
    }
  }, []);

  return (
    <CodinativeBoxWrapper objectFit={objectFit}>
      <p className="codinative-text disable-highlight">Codinative</p>
      <p className="forever-text disable-highlight">for ever</p>
    </CodinativeBoxWrapper>
  );
};

const CodinativeBoxWrapper = styled.div`
  margin-top: 80px;
  background-image: url(${StairImage});
  background-attachment: ${(props) => (props.objectFit ? "fixed" : "inherit")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 0 10px;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .codinative-text {
    font-weight: 600;
    font-size: 180px;
    margin: 0;
    color: transparent;
    -webkit-text-stroke: 4px ${Colors.light};
  }

  .forever-text {
    font-size: 20px;
    margin: 0;
    font-family: "Comfortaa", cursive;
    color: ${Colors.light};
  }

  //RESPANSIVE
  @media screen and (max-width: 980px) {
    .codinative-text {
      font-size: 120px;
    }
  }
  @media screen and (max-width: 680px) {
    height: 30vh;

    .codinative-text {
      font-size: 80px;
      -webkit-text-stroke: 2px white;
    }
  }
  @media screen and (max-width: 480px) {
    .codinative-text {
      font-size: 60px;
      -webkit-text-stroke: 2px white;
    }
  }
  @media screen and (max-width: 400px) {
    .codinative-text {
      font-size: 50px;
      -webkit-text-stroke: 1px white;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(CodinativeBox);
