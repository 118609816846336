import React from "react";
import styled from "styled-components";
import { Colors } from "../../../shared/Colors";

const FootersFlash = (props) => {
  return (
    <FootersFlashWrapper
      properties={props.properties}
      getUpFooter={props.getUpFooter}
    >
      <svg width="160" height="140">
        <path
          d="M 10 65 q 10 -50 60 -40 M 10 65 l 10 -8 M 6 53 l 4 12"
          className="line"
        />
        Sorry, your browser does not support inline SVG.
      </svg>
      {/* <span className="click-to-footer">Click here</span> */}
    </FootersFlashWrapper>
  );
};

const FootersFlashWrapper = styled.div`
  position: ${(props) => props.properties.position};
  top: ${(props) => props.properties.top};
  left: ${(props) => props.properties.left};
  transition: 1000ms;
  transform: ${(props) => (props.getUpFooter ? "scale(0,0)" : "scale(1,1)")};

  .line {
    fill: transparent;
    stroke: ${(props) => props.properties.color ?? Colors.dark};
    stroke-width: 1;
  }

  // .click-to-footer {
  //   position: absolute;
  //   font-size: 10px;
  //   top: 10px;
  // }

  //RESPANSIVE
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default FootersFlash;
