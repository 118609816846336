/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../shared/Colors";
import Dandelion from "../../../assets/Svgs/About/dandelion.svg";

const Clock = (props) => {
  const [motion, setMotion] = useState(true);
  useEffect(() => {
    setMotion(true);
  }, []);

  const [date, setDate] = useState(new Date());
  useEffect(() => {
    let Timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => clearInterval(Timer);
  }, []);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  const numsArray = [
    [1, 1, 1, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 1, 1],
    [1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1],
    [1, 1, 1, 0, 0, 1, 1, 1, 1, 1, 0, 0, 1, 1, 1],
    [1, 1, 1, 0, 0, 1, 1, 1, 1, 0, 0, 1, 1, 1, 1],
    [1, 0, 1, 1, 0, 1, 1, 1, 1, 0, 0, 1, 0, 0, 1],
    [1, 1, 1, 1, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 1],
    [1, 1, 1, 1, 0, 0, 1, 1, 1, 1, 0, 1, 1, 1, 1],
    [1, 1, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0],
    [1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1],
    [1, 1, 1, 1, 0, 1, 1, 1, 1, 0, 0, 1, 1, 1, 1],
  ];
  const [currTime, setCurrTime] = useState({
    hours: [],
    minutes: [],
    seconds: "",
  });

  useEffect(() => {
    whatHoursIsIt();
    whatMinutesIsIt();
    whatSecondsIsIt();
  }, [seconds]);

  const whatHoursIsIt = () => {
    var arr = [];
    if (hours === 1) {
      arr.push(numsArray[0], numsArray[1]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 2) {
      arr.push(numsArray[0], numsArray[2]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 3) {
      arr.push(numsArray[0], numsArray[3]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 4) {
      arr.push(numsArray[0], numsArray[4]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 5) {
      arr.push(numsArray[0], numsArray[5]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 6) {
      arr.push(numsArray[0], numsArray[6]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 7) {
      arr.push(numsArray[0], numsArray[7]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 8) {
      arr.push(numsArray[0], numsArray[8]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 9) {
      arr.push(numsArray[0], numsArray[9]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 10) {
      arr.push(numsArray[1], numsArray[0]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 11) {
      arr.push(numsArray[1], numsArray[1]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 12) {
      arr.push(numsArray[1], numsArray[2]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 13) {
      arr.push(numsArray[1], numsArray[3]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 14) {
      arr.push(numsArray[1], numsArray[4]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 15) {
      arr.push(numsArray[1], numsArray[5]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 16) {
      arr.push(numsArray[1], numsArray[6]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 17) {
      arr.push(numsArray[1], numsArray[7]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 18) {
      arr.push(numsArray[1], numsArray[8]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 19) {
      arr.push(numsArray[1], numsArray[9]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 20) {
      arr.push(numsArray[2], numsArray[0]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 21) {
      arr.push(numsArray[2], numsArray[1]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 22) {
      arr.push(numsArray[2], numsArray[2]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else if (hours === 23) {
      arr.push(numsArray[2], numsArray[3]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    } else {
      arr.push(numsArray[0], numsArray[0]);
      setCurrTime((prevState) => ({ ...prevState, hours: arr }));
    }
  };
  const whatMinutesIsIt = () => {
    const arr = [];
    if (minutes === 1) {
      arr.push(numsArray[0], numsArray[1]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 2) {
      arr.push(numsArray[0], numsArray[2]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 3) {
      arr.push(numsArray[0], numsArray[3]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 4) {
      arr.push(numsArray[0], numsArray[4]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 5) {
      arr.push(numsArray[0], numsArray[5]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 6) {
      arr.push(numsArray[0], numsArray[6]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 7) {
      arr.push(numsArray[0], numsArray[7]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 8) {
      arr.push(numsArray[0], numsArray[8]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 9) {
      arr.push(numsArray[0], numsArray[9]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 10) {
      arr.push(numsArray[1], numsArray[0]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 11) {
      arr.push(numsArray[1], numsArray[1]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 12) {
      arr.push(numsArray[1], numsArray[2]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 13) {
      arr.push(numsArray[1], numsArray[3]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 14) {
      arr.push(numsArray[1], numsArray[4]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 15) {
      arr.push(numsArray[1], numsArray[5]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 16) {
      arr.push(numsArray[1], numsArray[6]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 17) {
      arr.push(numsArray[1], numsArray[7]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 18) {
      arr.push(numsArray[1], numsArray[8]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 19) {
      arr.push(numsArray[1], numsArray[9]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 20) {
      arr.push(numsArray[2], numsArray[0]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 21) {
      arr.push(numsArray[2], numsArray[1]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 22) {
      arr.push(numsArray[2], numsArray[2]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 23) {
      arr.push(numsArray[2], numsArray[3]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 24) {
      arr.push(numsArray[2], numsArray[4]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 25) {
      arr.push(numsArray[2], numsArray[5]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 26) {
      arr.push(numsArray[2], numsArray[6]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 27) {
      arr.push(numsArray[2], numsArray[7]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 28) {
      arr.push(numsArray[2], numsArray[8]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 29) {
      arr.push(numsArray[2], numsArray[9]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 30) {
      arr.push(numsArray[3], numsArray[0]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 31) {
      arr.push(numsArray[3], numsArray[1]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 32) {
      arr.push(numsArray[3], numsArray[2]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 33) {
      arr.push(numsArray[3], numsArray[3]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 34) {
      arr.push(numsArray[3], numsArray[4]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 35) {
      arr.push(numsArray[3], numsArray[5]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 36) {
      arr.push(numsArray[3], numsArray[6]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 37) {
      arr.push(numsArray[3], numsArray[7]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 38) {
      arr.push(numsArray[3], numsArray[8]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 39) {
      arr.push(numsArray[3], numsArray[9]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 40) {
      arr.push(numsArray[4], numsArray[0]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 41) {
      arr.push(numsArray[4], numsArray[1]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 42) {
      arr.push(numsArray[4], numsArray[2]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 43) {
      arr.push(numsArray[4], numsArray[3]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 44) {
      arr.push(numsArray[4], numsArray[4]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 45) {
      arr.push(numsArray[4], numsArray[5]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 46) {
      arr.push(numsArray[4], numsArray[6]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 47) {
      arr.push(numsArray[4], numsArray[7]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 48) {
      arr.push(numsArray[4], numsArray[8]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 49) {
      arr.push(numsArray[4], numsArray[9]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 50) {
      arr.push(numsArray[5], numsArray[0]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 51) {
      arr.push(numsArray[5], numsArray[1]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 52) {
      arr.push(numsArray[5], numsArray[2]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 53) {
      arr.push(numsArray[5], numsArray[3]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 54) {
      arr.push(numsArray[5], numsArray[4]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 55) {
      arr.push(numsArray[5], numsArray[5]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 56) {
      arr.push(numsArray[5], numsArray[6]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 57) {
      arr.push(numsArray[5], numsArray[7]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 58) {
      arr.push(numsArray[5], numsArray[8]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else if (minutes === 59) {
      arr.push(numsArray[5], numsArray[9]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    } else {
      arr.push(numsArray[0], numsArray[0]);
      setCurrTime((prevState) => ({ ...prevState, minutes: arr }));
    }
  };
  const whatSecondsIsIt = () => {
    if (seconds === 1) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "01" }));
    } else if (seconds === 2) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "02" }));
    } else if (seconds === 3) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "03" }));
    } else if (seconds === 4) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "04" }));
    } else if (seconds === 5) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "05" }));
    } else if (seconds === 6) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "06" }));
    } else if (seconds === 7) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "07" }));
    } else if (seconds === 8) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "08" }));
    } else if (seconds === 9) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "09" }));
    } else if (seconds === 10) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "10" }));
    } else if (seconds === 11) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "11" }));
    } else if (seconds === 12) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "12" }));
    } else if (seconds === 13) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "13" }));
    } else if (seconds === 14) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "14" }));
    } else if (seconds === 15) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "15" }));
    } else if (seconds === 16) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "16" }));
    } else if (seconds === 17) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "17" }));
    } else if (seconds === 18) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "18" }));
    } else if (seconds === 19) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "19" }));
    } else if (seconds === 20) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "20" }));
    } else if (seconds === 21) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "21" }));
    } else if (seconds === 22) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "22" }));
    } else if (seconds === 23) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "23" }));
    } else if (seconds === 24) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "24" }));
    } else if (seconds === 25) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "25" }));
    } else if (seconds === 26) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "26" }));
    } else if (seconds === 27) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "27" }));
    } else if (seconds === 28) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "28" }));
    } else if (seconds === 29) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "29" }));
    } else if (seconds === 30) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "30" }));
    } else if (seconds === 31) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "31" }));
    } else if (seconds === 32) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "32" }));
    } else if (seconds === 33) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "33" }));
    } else if (seconds === 34) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "34" }));
    } else if (seconds === 35) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "35" }));
    } else if (seconds === 36) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "36" }));
    } else if (seconds === 37) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "37" }));
    } else if (seconds === 38) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "38" }));
    } else if (seconds === 39) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "39" }));
    } else if (seconds === 40) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "40" }));
    } else if (seconds === 41) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "41" }));
    } else if (seconds === 42) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "42" }));
    } else if (seconds === 43) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "43" }));
    } else if (seconds === 44) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "44" }));
    } else if (seconds === 45) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "45" }));
    } else if (seconds === 46) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "46" }));
    } else if (seconds === 47) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "47" }));
    } else if (seconds === 48) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "48" }));
    } else if (seconds === 49) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "49" }));
    } else if (seconds === 50) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "50" }));
    } else if (seconds === 51) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "51" }));
    } else if (seconds === 52) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "52" }));
    } else if (seconds === 53) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "53" }));
    } else if (seconds === 54) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "54" }));
    } else if (seconds === 55) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "55" }));
    } else if (seconds === 56) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "56" }));
    } else if (seconds === 57) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "57" }));
    } else if (seconds === 58) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "58" }));
    } else if (seconds === 59) {
      setCurrTime((prevState) => ({ ...prevState, seconds: "59" }));
    } else {
      setCurrTime((prevState) => ({ ...prevState, seconds: "00" }));
    }
  };

  const clockText = () => {
    if (hours === 11 && minutes === 11) {
      return (
        <div className=" disable-highlight">
          Make a wish{" "}
          <img
            src={Dandelion}
            alt="dandelion"
            className="dandelion disable-highlight"
          />
        </div>
      );
    } else if (hours === 0 && minutes === 0) {
      return <div className=" disable-highlight">Believe in yourself</div>;
    }
  };

  return (
    <ClockWrapper motion={motion}>
      <div className="clock-box">
        <div className="each-number first-hour">
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][0]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "00"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              00
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][1]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "09"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              09
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][2]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "10"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              10
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][3]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "01"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              01
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][4]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "08"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              08
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][5]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "11"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              11
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][6]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "02"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              02
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][7]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "07"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              07
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][8]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "12"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              12
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][9]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "03"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              03
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][10]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "06"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              06
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][11]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "13"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              13
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][12]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "04"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              04
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][13]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "05"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              05
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[0][14]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "14"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              14
            </span>
          </div>
        </div>
        <div className="each-number second-hour">
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][0]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "19"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              19
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][1]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "20"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              20
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][2]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "29"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              29
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][3]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "18"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              18
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][4]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "21"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              21
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][5]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "28"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              28
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][6]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "17"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              17
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][7]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "22"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              22
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][8]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "27"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              27
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][9]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "16"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              16
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][10]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "23"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              23
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][11]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "26"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              26
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][12]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "15"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              15
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][13]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "24"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              24
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.hours.length && currTime.hours[1][14]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "25"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              25
            </span>
          </div>
        </div>
        <div className="seperator disable-highlight">:</div>
        <div className="each-number first-minute">
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][0]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "30"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              30
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][1]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "39"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              39
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][2]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "40"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              40
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][3]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "31"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              31
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][4]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "38"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              38
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][5]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "41"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              41
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][6]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "32"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              32
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][7]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "37"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              37
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][8]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "42"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              42
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][9]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "33"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              33
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][10]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "36"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              36
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][11]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "43"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              43
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][12]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "34"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              34
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][13]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "35"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              35
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[0][14]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "44"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              44
            </span>
          </div>
        </div>
        <div className="each-number second-minute">
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][0]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "49"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              49
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][1]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "50"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              50
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][2]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "59"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              59
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][3]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "48"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              48
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][4]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "51"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              51
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][5]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "58"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              58
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][6]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "47"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              47
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][7]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "52"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              52
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][8]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "57"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              57
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][9]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "46"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              46
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][10]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "53"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              53
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][11]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "56"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              56
            </span>
          </div>
          <div className="each-floor-of-each-number">
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][12]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "45"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              45
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][13]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "54"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              54
            </span>
            <span
              className={[
                "each-second",
                "disable-highlight",
                currTime.minutes.length && currTime.minutes[1][14]
                  ? "current-time"
                  : "",
                currTime.seconds && currTime.seconds === "55"
                  ? "second-counter"
                  : "",
              ].join(" ")}
            >
              55
            </span>
          </div>
        </div>
      </div>
      <p className="clock-text">{clockText()}</p>
    </ClockWrapper>
  );
};

const ClockWrapper = styled.div`
  width: 80%;
  margin: 100px auto;

  .clock-box {
    width: 45%;
    margin: auto;
    display: flex;
    align-items: center;
  }
  .seperator {
    color: ${Colors.purple};
    font-size: 80px;
    margin: 0 0 20px 0;
  }
  .each-number {
    width: 25%;
    padding: 10px;
  }
  .each-floor-of-each-number {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .each-second {
    // color: ${Colors.lightPurple};
    color: #ecedef;
  }
  .current-time {
    color: ${Colors.darkPurple};
    transform: scale(1.2, 1.2);
    transition: 2000ms ease;
    opacity: ${(props) => (props.motion ? "1" : "0")};
  }
  .second-counter {
    color: ${Colors.purple};
    transition: 200ms ease;
    transform: scale(1.2, 1.2);
  }
  .clock-text {
    font-size: 20px;
    text-align: center;
    color: ${Colors.purple};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dandelion {
    width: 20px;
    height: 20px;
  }

  //RESPANSIVE
  @media screen and (max-width: 1300px) {
    .clock-box {
      width: 50%;
    }
  }
  @media screen and (max-width: 1200px) {
    .clock-box {
      width: 60%;
    }
  }
  @media screen and (max-width: 1000px) {
    .clock-box {
      width: 75%;
    }
  }
  @media screen and (max-width: 800px) {
    width: 100%;

    .clock-box {
      width: 60%;
    }
    .seperator {
      color: ${Colors.purple};
      font-size: 50px;
      margin: 0 0 20px 0;
    }
    .each-number {
      font-size: 12px;
      padding: 10px;
    }
  }
  @media screen and (max-width: 650px) {
    .clock-box {
      width: 70%;
    }
  }
  @media screen and (max-width: 600px) {
    .clock-box {
      width: 80%;
    }
  }
  @media screen and (max-width: 500px) {
    .clock-box {
      width: 85%;
    }
    .seperator {
      color: ${Colors.purple};
      font-size: 40px;
      margin: 0 0 20px 0;
    }
    .each-number {
      font-size: 10px;
      padding: 10px;
    }
  }
  @media screen and (max-width: 400px) {
    .clock-box {
      width: 100%;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(Clock);
