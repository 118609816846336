import React, { useState } from "react";
import PropertiesImage from "../../assets/Images/Properties/properties.jpg";
import InnovativeIcon from "../../assets/Svgs/Properties/innovative.svg";
import RegularIcon from "../../assets/Svgs/Properties/regular.svg";
import TimeIcon from "../../assets/Svgs/Properties/time.svg";
import ResponsiveIcon from "../../assets/Svgs/Properties/responsive.svg";
import styled from "styled-components";
import { Colors } from "../../shared/Colors";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const Properties = (props) => {
  const [motion, setMotion] = useState(true);
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -960) {
      setMotion(false);
    } else if (currPos.y > -960) {
      setMotion(true);
    }
  });

  return (
    <PropertiesWrapper id={props.id} motion={motion}>
      <p className="main-title disable-highlight">Properties</p>
      <div className="properties-box">
        <div className="left-box"></div>
        <div className="right-box">
          <div className="right-top">
            <div className="right-top-left">
              <div className="right-head">
                <img
                  src={InnovativeIcon}
                  alt="icon"
                  className="right-icons disable-highlight"
                />
                <span className="disable-highlight">Innovative</span>
              </div>
              <div className="right-text">
                <p className="disable-highlight">
                  Creativity, difference and attractiveness are the creators of
                  the success cycle so that you will be different from
                  creativity and you will be seen attractive by this difference,
                  so it is the attractiveness that will make your website design
                  successful and therefore lasting.
                </p>
              </div>
            </div>
            <div className="right-top-right">
              <div className="right-head">
                <img
                  src={RegularIcon}
                  alt="icon"
                  className="right-icons disable-highlight"
                />
                <span className="disable-highlight">Regular</span>
              </div>
              <div className="right-text">
                <p className="disable-highlight">
                  Having a site that is partially designed and modulated is a
                  great advantage. It will also be easier to edit and
                  troubleshoot. A site with code neatness and segmentation and
                  regular is one of the tasks of our team.
                </p>
              </div>
            </div>
          </div>
          <div className="right-bottom">
            <div className="right-bottom-left">
              <div className="right-head">
                <img
                  src={TimeIcon}
                  alt="icon"
                  className="right-icons disable-highlight"
                />
                <span className="disable-highlight">Time</span>
              </div>
              <div className="right-text">
                <p className="disable-highlight">
                  In addition to beauty, efficiency and creativity in execution,
                  it is important that the design of your site is done according
                  to a precise schedule and on time. Due to the high growth rate
                  of online businesses, delays in the implementation or delivery
                  process can be an important obstacle to your success.
                </p>
              </div>
            </div>
            <div className="right-bottom-right">
              <div className="right-head">
                <img
                  src={ResponsiveIcon}
                  alt="icon"
                  className="right-icons disable-highlight"
                />
                <span className="disable-highlight">Responsive</span>
              </div>
              <div className="right-text">
                <p className="disable-highlight">
                  Public access is one of the advantages of a good site, it is
                  especially important to see the design of the website with a
                  neat and tidy appearance with any tools that the user uses.
                  Different tools have different platforms for creativity that
                  we take full advantage of.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PropertiesWrapper>
  );
};

const PropertiesWrapper = styled.div`
  font-family: "Comfortaa", cursive;
  width: 100%;
  margin: 40px auto;

  .main-title {
    width: 90%;
    float: right;
    font-size: 30px;
    font-weight: 600;
    color: ${Colors.darkPurple};
    transition: 1200ms ease;
    opacity: ${(props) => (props.motion ? "0" : "1")};
  }

  .properties-box {
    display: flex;
    width: 100%;
  }
  .left-box {
    width: 40%;
    height: 80vh;
    background-image: url(${PropertiesImage});
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10px;
    transition: 1200ms;
    position: relative;
    box-shadow: 0 0 10px;
    left: ${(props) => (props.motion ? "-1000px" : "0")};
  }
  .right-box {
    width: 60%;
    height: 80vh;
    display: block;
    background-color: ${Colors.light};
    padding: 10px;
  }
  .right-top {
    width: 100%;
    display: flex;
  }
  .right-bottom {
    width: 100%;
    display: flex;
  }
  .right-top-left {
    width: 50%;
    height: 40vh;
    padding: 10px;
    transition: 1000ms ease;
    transform: ${(props) =>
      props.motion ? "scale(0.001,0.001)" : "scale(1,1)"};
    opacity: ${(props) => (props.motion ? "0" : "1")};
  }
  .right-top-right {
    width: 50%;
    height: 40vh;
    padding: 10px;
    transition: 1000ms ease;
    transform: ${(props) =>
      props.motion ? "scale(0.001,0.001)" : "scale(1,1)"};
    opacity: ${(props) => (props.motion ? "0" : "1")};
  }
  .right-bottom-left {
    width: 50%;
    height: 40vh;
    padding: 10px;
    transition: 1600ms ease;
    transform: ${(props) =>
      props.motion ? "scale(0.001,0.001)" : "scale(1,1)"};
    opacity: ${(props) => (props.motion ? "0" : "1")};
  }
  .right-bottom-right {
    width: 50%;
    height: 40vh;
    padding: 10px;
    transition: 1600ms ease;
    transform: ${(props) =>
      props.motion ? "scale(0.001,0.001)" : "scale(1,1)"};
    opacity: ${(props) => (props.motion ? "0" : "1")};
  }
  .right-head {
    font-size: 20px;
    color: ${Colors.purple};
    display: flex;
    align-items: center;
  }
  .right-icons {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .right-text {
    font-size: 16px;
  }

  //RESPANSIVE
  @media screen and (max-width: 920px) {
    .main-title {
      width: 100%;
      text-align: center;
      font-size: 28px;
    }
    .right-head {
      font-size: 18px;
    }
    .right-icons {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }
    .right-text {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 800px) {
    .main-title {
      width: 100%;
      text-align: center;
      font-size: 24px;
    }
    .right-head {
      font-size: 18px;
    }
    .right-icons {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
    .right-text {
      font-size: 14px;
    }
    .left-box {
      width: 30%;
    }
    .right-box {
      width: 70%;
    }
  }
  @media screen and (max-width: 620px) {
    .left-box {
      height: 50vh;
    }
    .right-box {
      height: 50vh;
    }
    .right-head {
      font-size: 16px;
    }
    .right-icons {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
    .right-text {
      font-size: 10px;
    }
    .right-top-left {
      height: 25vh;
    }
    .right-top-right {
      height: 25vh;
    }
    .right-bottom-left {
      height: 25vh;
    }
    .right-bottom-right {
      height: 25vh;
    }
  }
  @media screen and (max-width: 560px) {
    .left-box {
      width: 0%;
      display: none;
    }
    .right-box {
      width: 100%;
    }
    .left-box {
      height: 48vh;
    }
    .right-box {
      height: 48vh;
    }
    .right-top-left {
      height: 24vh;
    }
    .right-top-right {
      height: 24vh;
    }
    .right-bottom-left {
      height: 24vh;
    }
    .right-bottom-right {
      height: 24vh;
    }
  }
  //RESPANSIVE

  .disable-highlight {
    ::selection {
      color: none;
      background: none;
    }
    /* For Mozilla Firefox */
    ::-moz-selection {
      color: none;
      background: none;
    }
  }
`;

export default React.memo(Properties);
