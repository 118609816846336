import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { browserName, CustomView } from "react-device-detect";
import AnimatedCursor from "react-animated-cursor";
import Footer from "../../components/Footer/Footer";
import GoUp from "../../components/GoUp/GoUp";
import Toolbar from "../../components/Toolbar/Toolbar";

const Layout = (props) => {
  const [dontBeChrome, setDontBeChrome] = useState(false);
  useEffect(() => {
    if (browserName === "Chrome") {
      setDontBeChrome(true);
    }
  }, []);
  return (
    <LayoutWrapper>
      <CustomView condition={browserName === "Chrome"}>
        <AnimatedCursor
          innerSize={8}
          outerSize={12}
          color="120, 19, 254"
          outerAlpha={0.2}
          innerScale={0.1}
          outerScale={10}
        />
      </CustomView>
      {props.showToolbar && <Toolbar dontBeChrome={dontBeChrome} />}
      <main className="Layout">{props.children}</main>
      {props.showGoUp && <GoUp />}
      {props.showFooter && <Footer dontBeChrome={dontBeChrome} />}
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled.div`
  .Layout {
    width: 100%;
  }
`;

export default Layout;
